import React, { Component} from 'react';

export default class DinningDetail extends Component{
    
    constructor(props){
        super(props);

    }

    render(){
      return (
        <div>
            <div className="header-cover" style={{backgroundImage: 'url(uploads/banner.png)'}}>
                <div className="bg-gradient">
                <div className="d-flex justify-content-between bd-highlight mb-3">
                    <div className="p-2 pl-3 bd-highlight"><a href="/login"><img src="icon/menuwhite@2x.png" className="icon-hamberger" /></a></div>
                    <div className="p-2 pr-3 bd-highlight">
                    </div>
                    <div className="p-2 bd-highlight"><div className="lang">EN</div></div>
                </div>
                <div className="zone-center-detail" />
                </div>
            </div>
            <div className="main">
                <div className="container">
                <article>
                    <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <div className="card mt-sub50 overflow-visible">
                            <div className="card-body">
                                <div className="logo">
                                <div className="logo-hotel logo-seven">
                                    <img src="uploads/logo-seven.jpg" />
                                </div>
                                </div>
                                <h3 className="text-center mt-1">Seven Eleven</h3>
                                <p className="card-text mt-3 p-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </article>
                <article>
                    <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <h2 className="m-3">NEAREST FOR YOU</h2>
                        <div className="card p-3">
                            <div className="card-body">
                                <div className="row">
                                <div className="col-8">
                                    <h3 className="mb-4">Seven Eleven</h3>
                                    <p className="text-blue"><i className="fa fa-map-marker" aria-hidden="true" /> Terminal 1 <span className="text-gray">|</span> 2 Fl. <span className="text-gray">|</span> Departure Hall</p>
                                    <p><i className="fa fa-clock-o" aria-hidden="true" /> Mon - Fri, 06.00 AM - 23.50 PM</p>
                                </div>
                                <div className="col-4 icon-map">
                                    <i className="fa fa-map" aria-hidden="true" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-3">
                            <div className="card-body">
                                <div className="row">
                                <div className="col-8">
                                    <h3 className="mb-4">Seven Eleven</h3>
                                    <p className="text-blue"><i className="fa fa-map-marker" aria-hidden="true" /> Terminal 2 <span className="text-gray">|</span> 2 Fl. <span className="text-gray">|</span> Departure Hall</p>
                                    <p><i className="fa fa-clock-o" aria-hidden="true" /> Mon - Fri, 06.00 AM - 23.50 PM</p>
                                </div>
                                <div className="col-4 icon-map">
                                    <i className="fa fa-map" aria-hidden="true" />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </article>
                </div>
                <footer />
            </div>
        </div>
    );
    }
  };