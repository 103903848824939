import React, { Component} from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { config } from '../Constants';
import { encode } from 'base-64';
import { authentication } from '../services/Authentication';
import { BehaviorSubject } from 'rxjs';
import MenuSlide from '../component/MenuSlide';

const currentLanguage = new BehaviorSubject(JSON.parse(localStorage.getItem('language')));

export default class Bar extends Component{

    constructor(props){
        super(props);
        this.state = {
            search:'',
            pagesLang: [],
            langIsOpen: false,
        }

        this.openNav = this.openNav.bind(this);
        this.change = this.change.bind(this);
        this.openLang = this.openLang.bind(this);

        this.openLang = this.openLang.bind(this);
        this.closeLang = this.closeLang.bind(this);
        this.submitLang = this.submitLang.bind(this);
        this.dataLang();
    }

    async componentWillMount(){
        const { lang, lang_id } = await (currentLanguage.value?currentLanguage.value:authentication.getLanguage());

        this.setState({
            lang:lang,
            lang_id: lang_id,
        })
        this.dataLang();
    }

    openNav() {
        document.getElementById("mySidenav").style.width = "280px";
    }

    change(e){
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    openLang() {
        this.setState({
            langIsOpen: true
        });
    }

    closeLang() {
        this.setState({
            langIsOpen: false
        });
    }

    submitLang(e) {
        //console.log(JSON.stringify(this.props))
        this.props.onChageLangSubmit(e.currentTarget.attributes["data-short"].value, e.currentTarget.attributes["data-id"].value)
        var data = {
            lang: e.currentTarget.attributes["data-short"].value,
            lang_id: e.currentTarget.attributes["data-id"].value
        }

        localStorage.setItem('language', JSON.stringify(data));
        this.setState({
            langIsOpen: false,
            lang: data['lang']
        });
        //this.dataLang();
        window.location.reload();
    }

    dataLang() {

        const requestOptions = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encode(config.url.USER + ':' + config.url.PASS),
                'Language': this.state.lang_id,
            },
        };

        fetch(config.url.API_URL + '/language'
            , requestOptions)
            .then(res => res.json())
            .then(data => {
                if (Array.isArray(data.data) && data.data.length) {
                    var FromApi = data.data.map(datas => {
                        return {
                            id: datas.lang_id,
                            logo: datas.lang_logo,
                            short_name: datas.lang_short_name,
                            name: datas.lang_name,
                            default: datas.lang_default
                        }
                    });
                    this.setState({
                        pagesLang: [].concat(FromApi),
                        srcIsOpen: false
                    });
                    // console.log(JSON.stringify(this.state.pages));
                } else {
                    this.setState({
                        pagesLang: []
                    });
                }

            });
    }

    render(){
      return (
        <div>
        <MenuSlide langDatas={this.state.pagesLang}/>
        <div className="header-cover" style={{background:this.props.background}}>
            <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="bg-gradient-ut">
                    <div className="d-flex justify-content-between bd-highlight mb-3">
                        <div className="p-2 pl-3 bd-highlight cursor-point"><img src={"../icon/menu@2x.png"} className="icon-hamberger" onClick={this.openNav}/></div>
                        <div className="p-2 pr-3bd-highlight">
                            <div className="input-group input-search">
                                <form action="/search" method="GET">
                                    <input type="text" name="s" onChange={this.change} className="form-control" placeholder="Search flight,shop,service" />
                                    <input type="hidden" name="src_type" value={this.props.src_type}/>
                                </form>
                            </div>
                        </div>
                        <div className="p-2 bd-highlight"><div className="lang mt-2"><a href="#" onClick={this.openLang} className="text-gray">{this.state.lang}</a></div></div>
                    </div>
                </div>
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carouselExampleIndicators"data-bs-slide-to={0} className="active" />
                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} />
                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} />
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={"../uploads/slider/slide3.jpg"} className="w-100 slide-desktop" alt="..." />
                            <img src={"../uploads/slider/slide3-mobile.jpg"} className="w-100 slide-mobile" alt="..." />
                        </div>
                        <div className="carousel-item ">
                            <img src={"../uploads/slider/slide2.jpg"} className="w-100 slide-desktop" alt="..." />
                            <img src={"../uploads/slider/slide2-mobile.jpg"} className="w-100 slide-mobile" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={"../uploads/slider/slide1.jpg"} className="w-100 slide-desktop" alt="..." />
                            <img src={"../uploads/slider/slide1-mobile.jpg"} className="w-100 slide-mobile" alt="..." />
                        </div>
                    </div>
            </div>
            </div>
            <Modal show={this.state.langIsOpen} onHide={this.closeLang}>
                <ModalHeader>
                    <ModalTitle>Select language</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form>
                        { this.state.pagesLang.map((data) => {
                            return (
                            <div className="radio">
                                <label><h5><a data-short={data.short_name} onClick={this.submitLang} data-id={data.id}>{data.name}</a></h5></label>
                            </div>
                            )
                        })
                        }
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-danger" onClick={this.closeLang}>ยกเลิก</button>
                </ModalFooter>
            </Modal>
        </div>
    );
    }
  };
