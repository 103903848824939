import React, { Component} from 'react';

export default class HotelDetail extends Component{
    
    constructor(props){
        super(props);

    }

    render(){
      return (
        <div>
            <div className="header-cover" style={{backgroundImage: 'url(uploads/banner.png)'}}>
                <div className="bg-gradient">
                <div className="d-flex justify-content-between bd-highlight mb-3">
                    <div className="p-2 pl-3 bd-highlight"><a href="/login"><img src="icon/logo-back.png" className="icon-back" /></a></div>
                    <div className="p-2 pr-3 bd-highlight">
                    </div>
                    <div className="p-2 bd-highlight"></div>
                </div>
                <div className="zone-center-detail">
                    </div>
                </div>
            </div>
            <div className="main">
                <div className="container">
                <article>
                    <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <div className="card mt-sub50 overflow-visible">
                        <div className="card-body p-5">
                            <div className="logo">
                            <div className="logo-hotel">
                                <img src="uploads/logo-hotel.jpg" />
                            </div>
                            </div>
                            <div className="text-center  mt-4">
                            <i className="fa fa-star text-gold" aria-hidden="true" />
                            <i className="fa fa-star text-gold" aria-hidden="true" />
                            <i className="fa fa-star text-gold" aria-hidden="true" />
                            <i className="fa fa-star text-gold" aria-hidden="true" />
                            <i className="fa fa-star text-gold" aria-hidden="true" />
                            </div>
                            <h3 className="text-center mt-1">Ocean Hill</h3>
                            <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <div className="row pt-0">
                            <div className="col-xs-12 col-md-12 col-lg-6">
                            <table className="tb-list">
                                <tbody><tr>
                                    <th><div className="number-chart">4.5</div></th>
                                    <th><p className="m-0">Excellent</p></th>
                                </tr>
                                <tr>
                                    <td>
                                    <div className="dot-chart">
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle-o" aria-hidden="true" />
                                    </div>
                                    </td>
                                    <td><div className="hotel-properties">Location</div></td>
                                </tr>
                                <tr>
                                    <td>
                                    <div className="dot-chart">
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                    </div>
                                    </td>
                                    <td><div className="hotel-properties">Cleanliness</div></td>
                                </tr>
                                <tr>
                                    <td>
                                    <div className="dot-chart">
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle-o" aria-hidden="true" />
                                        <i className="fa fa-circle-o" aria-hidden="true" />
                                    </div>
                                    </td>
                                    <td><div className="hotel-properties">Service</div></td>
                                </tr>
                                <tr>
                                    <td>
                                    <div className="dot-chart">
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                        <i className="fa fa-circle" aria-hidden="true" />
                                    </div>
                                    </td>
                                    <td><div className="hotel-properties">Value</div></td>
                                </tr>
                                </tbody></table>
                            </div>
                            <div className="col-xs-12 col-md-12 col-lg-6">
                            <table className="tb-list">
                                <tbody><tr>
                                    <th colSpan={2}><p className="text-center m-0">Property amenities</p></th>
                                </tr>
                                <tr>
                                    <td><div className="hotel-properties"><i className="fa fa-car" aria-hidden="true" /> Free parking</div></td>
                                    <td><div className="hotel-properties"><i className="fa fa-wifi" aria-hidden="true" /> Free (WiFi)</div></td>
                                </tr>
                                <tr>
                                    <td><div className="hotel-properties"><i className="fa fa-life-ring" aria-hidden="true" /> Pool</div></td>
                                    <td><div className="hotel-properties"><i className="fa fa-heart" aria-hidden="true" /> Fitness Center</div></td>
                                </tr>
                                <tr>
                                    <td><div className="hotel-properties"><i className="fa fa-glass" aria-hidden="true" /> Bar / lounge</div></td>
                                    <td><div className="hotel-properties"><i className="fa fa-wifi" aria-hidden="true" /> Beach</div></td>
                                </tr>
                                <tr>
                                    <td><div className="hotel-properties"><i className="fa fa-futbol-o" aria-hidden="true" /> Tennis court</div></td>
                                    <td><div className="hotel-properties"><i className="fa fa-child" aria-hidden="true" /> Babysitting</div></td>
                                </tr>
                                </tbody></table>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </article>
                <article>
                    <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <h2 className="m-3">LOCATION</h2>
                        <div className="card">
                            <div className="card-body p-3">
                                    <h3 className="mb-4">Ocean Hill</h3>
                                    <p><i className="fa fa-map-marker text-orange" aria-hidden="true" /> 353 Phra Tamnuk Road Pattaya 20150, Thailand</p>
                                    <p><i className="fa fa-phone text-green" aria-hidden="true" /> (+66) 38 250421, (+66) 2 2947272</p>
                                    <p><i className="fa fa-envelope-o text-blue" aria-hidden="true" /> gro-main@ralyelcliff.com</p>
                            </div>
                            <div className="card-footer p-0">
                                <div className="row">
                                    <div className="col text-center bg-blue p-2">
                                        <img src="icon/icon-map.png" width="20" height="auto" style={{marginTop:"-10px"}}/><span className="text-white"> GETTING HERE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </article>
                </div>
                <footer />
            </div>
        </div>
    );
    }
  };