import React, { Component } from "react";
import Bar from "../component/Bar";
import Menu from "../component/Menu";
import { config } from "../Constants";
import { encode } from "base-64";

import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

import ReactHtmlParser from "react-html-parser";
import "bootstrap/dist/css/bootstrap.min.css";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class Home extends Component {
  constructor(props) {
    super(props);
    //console.log("********"+JSON.stringify(currentLanguage.value))
    this.state = {
      events: [],
      suggestions: [],
      html: [],
      addIsOpen: false,
      DialogIsOpen: "block",
    };
    this.dataTable = this.dataTable.bind(this);
    this.suggestionsDetail = this.suggestionsDetail.bind(this);
    this.eventsDetail = this.eventsDetail.bind(this);
    this.closeAdd = this.closeAdd.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });
    //console.log("componentWillMount>>currentLanguage.value:"+currentLanguage.value["lang"]);
    //console.log("componentWillMount>>lang_id:"+lang_id);
    this.dataTable();
  }

  suggestionsDetail(e) {
    this.props.history.push(
      "/shopping-detail/" +
        e.currentTarget.attributes["id"].value +
        "/" +
        e.currentTarget.attributes["type"].value
    );
  }

  eventsDetail(e) {
    this.props.history.push(
      "/event-detail/" + e.currentTarget.attributes["id"].value
    );
  }

  closeAdd() {
    this.setState({ addIsOpen: false });
  }

  closeDialog() {
    this.setState({ dialogIsOpen: "none" });
    cookies.set("banner", true, { path: "/", httpOnly: true });
  }

  dataTable() {
    console.log("lang_id:" + this.state.lang_id);
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };
    // console.log("this.state.lang_id"+this.state.lang_id);
    fetch(config.url.API_URL + "/getevents?rows=4", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          var FromApi = data.data.map((datas) => {
            return {
              id: datas.event_id,
              title: datas.event_title,
              content: datas.event_content.substring(0, 50),
              img: datas.event_img,
              type: datas.event_type,
              date: datas.event_date,
            };
          });

          this.setState({
            events: [].concat(FromApi),
          });
        }
      });

    fetch(config.url.API_URL + "/suggestions?rows=4", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          var FromApi = data.data.map((datas) => {
            return {
              id: datas.is_id,
              title: datas.is_name,
              content: datas.is_detail.substring(0, 50),
              img: datas.is_img,
              date: datas.is_work_day,
              type: datas.is_type,
            };
          });

          this.setState({
            suggestions: [].concat(FromApi),
          });
        }
      });
  }

  changeLang(val, id) {
    this.setState({
      lang: val,
      lang_id: id,
    });
  }

  render() {
    document.body.style.background = "";
    return (
      <div>
        <Bar onChageLangSubmit={this.changeLang} page="home" src_type="all" />
        <div className="main">
          <div className="container">
            <Menu lang={this.state.lang} />
            <section style={{ padding: "0", marginTop: "20px" }}>
              <h2>
                <img src="icon/suggestions.png" className="icon-home" />
                {(this.state.lang == "TH" && <span>คำแนะนำ</span>) ||
                  (this.state.lang == "AR" && <span>اقتراحات</span>) ||
                  (this.state.lang == "RU" && <span>ПРЕДЛОЖЕНИЯ</span>) ||
                  (this.state.lang == "ZH" && <span>几点建议</span>) || (
                    <span>SUGGESTIONS</span>
                  )}
                <a href="/shopping" className="btn-more">
                  {(this.state.lang == "TH" && "ทั้งหมด") ||
                    (this.state.lang == "AR" && "المزيد") ||
                    (this.state.lang == "RU" && "более") ||
                    (this.state.lang == "ZH" && "更多的") ||
                    "More"}
                </a>
              </h2>
              <div className="container-home">
                <div className="row row-home">
                  {this.state.suggestions.slice(0, 4).map((data, i) => {
                    return (
                      <div className="col-3">
                        <div className="card cursor-point">
                          <a
                            onClick={this.suggestionsDetail}
                            id={data.id}
                            type={data.type}
                          >
                            <div className="home-img-ex">
                              <img
                                src={data.img}
                                className="img-thumb"
                                alt="..."
                              />
                            </div>
                            <div className="card-body">
                              <h3 className="card-title" className="text-black">
                                {data.title}
                              </h3>
                              <p className="card-text text-gray">
                                {data.content}
                              </p>
                              <p className="card-date">
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                />{" "}
                                {data.date}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
          <footer />
        </div>
        {cookies.get("banner") === false ||
          (cookies.get("banner") === undefined && (
            <div>
              <div className="banner" style={{ display: "none" }}>
                <div className="box">
                  <div className="close">
                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                  </div>
                  <a
                    href="https://thaiboxingapp.verkoffice.com/demo_utapao/"
                    target="_blank"
                  >
                    <img src="uploads/Desktop-Banner.png" />
                  </a>
                </div>
              </div>
              <div
                className="banner-mobile"
                style={{ display: this.state.dialogIsOpen }}
              >
                <div className="box">
                  <div className="close" onClick={this.closeDialog}>
                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                  </div>
                  <a
                    href="https://thaiboxingapp.verkoffice.com/demo_utapao/"
                    target="_blank"
                  >
                    <img src="uploads/Mobile-Banner.png" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        <Modal show={this.state.addIsOpen} onHide={this.closeAdd} size="lg">
          <ModalHeader closeButton></ModalHeader>
          {ReactHtmlParser(this.state.html)}
        </Modal>
      </div>
    );
  }
}
