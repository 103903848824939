import React, { Component } from "react";
import BarCover from "./BarCover";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class BaggageClaimInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      address: "",
    };
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });
  }

  async changeLang(val, id) {
    await this.setState({
      lang: val,
      lang_id: id,
    });
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          background={'url("uploads/banner.png") no-repeat center center'}
          title={
            (this.state.lang == "TH" && "กระเป๋า") ||
            (this.state.lang == "AR" && "استلام الأمتعة") ||
            (this.state.lang == "RU" && "ПОЛУЧЕНИЕ БАГАЖА") ||
            (this.state.lang == "ZH" && "领取行李") ||
            "BAGGAGE CLAIM"
          }
          method=""
        />
        <div className="main main-detail">
          <div className="container">
            <article>
              <h2 className="text-center m-3">
                {(this.state.lang == "TH" &&
                  "กรุณาติดต่อที่เคาน์เตอร์ประชาสัมพันธ์สำหรับการเคลมสัมภาระ") ||
                  "Please contact information counter for Baggage Claim"}
              </h2>
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="card" style={{ minHeight: "35px" }}>
                    <div className="card-body p-4">
                      <h3 className="mb-2">
                        {(this.state.lang == "TH" &&
                          "เคาน์เตอร์ประชาสัมพันธ์") ||
                          "Information Counter"}
                      </h3>
                      <p className="text-blue mb-2">
                        <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                        {(this.state.lang == "TH" &&
                          "เทอร์มินอล 2 | ชั้น 1 | เทอร์มินอลหลัก") ||
                          "Terminal 2 | Floor 1 | Main Terminal"}
                      </p>
                      <p className="mb-2">
                        <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                        {(this.state.lang == "TH" &&
                          "จันทร์ - อาทิตย์, 08:30 - 20:30 น.") ||
                          "Mon - Sun, 08:30 AM - 20:30 PM"}
                      </p>
                    </div>
                    <div
                      className="card-footer p-0"
                      style={{ display: "none" }}
                    >
                      <div className="row">
                        <div className="col text-center bg-blue p-2">
                          <img
                            src="icon/icon-map.png"
                            width="20"
                            height="auto"
                            style={{ marginTop: "-10px" }}
                          />
                          <span className="text-white"> GETTING HERE</span>
                        </div>
                        <div className="col text-center bg-darkblue p-2">
                          <img
                            src="icon/icon-clock.png"
                            width="20"
                            height="auto"
                            style={{ marginTop: "-5px" }}
                          />
                          <span className="text-white"> BOOKING</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
