import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";

import { isMobile } from "react-device-detect";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);
export default class Shopping extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const src = params.get("s");
    this.state = {
      languages: [],
      pages: [],
      categorys: [],
      src: src ? src : "",
    };

    this.dataTable = this.dataTable.bind(this);
    this.shoppingDetail = this.shoppingDetail.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.dataCategory = this.dataCategory.bind(this);
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable("", "");
    this.dataCategory();
  }

  dataTable(category_id, category) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Language: this.state.lang_id,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
    };
    this.setState({
      category_id: category_id,
      category: category,
    });
    fetch(
      config.url.API_URL +
        "/customerservice?category_cat=" +
        (category ? category : "all") +
        "&category_id=" +
        (category_id ? category_id : "shop") +
        (this.state.src && "&keyword=" + this.state.src),
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              shop_id: datas.is_id,
              shop_name: datas.is_name,
              shop_terminal: datas.is_terminal,
              shop_detail: datas.is_detail.substring(0, 90),
              shop_logo: datas.is_img,
              shop_cover: datas.is_img_cover,
              type: datas.is_type,
              address: datas.is_address,
            };
          });
          this.setState({
            pages: [].concat(FromApi),
            srcIsOpen: false,
          });
        } else {
          this.setState({
            pages: [],
            srcIsOpen: false,
          });
        }
      });
  }

  async changeCategory(e) {
    var elems = document.querySelectorAll(".item");

    [].forEach.call(elems, function (el) {
      el.classList.remove("item-active");
    });
    this.dataTable(
      e.currentTarget.attributes["id"].value,
      e.currentTarget.attributes["category"].value
    );
    const id = await e.target.id;
    document.getElementById(id).classList.add("item-active");
  }

  dataCategory() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Language: this.state.lang_id,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    fetch(
      config.url.API_URL + "/customerservicecategory" + "?type=shop",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              id: datas.category_id,
              name: datas.category_name,
              detail: datas.category_detail,
              img: datas.category_img,
              category: datas.category_cat,
            };
          });
          this.setState({
            categorys: [].concat(FromApi),
            srcIsOpen: false,
          });
        }
      });
  }

  shoppingDetail(e) {
    this.props.history.push(
      "/shopping-detail/" +
        e.currentTarget.attributes["id"].value +
        "/" +
        (e.currentTarget.attributes["type"].value === "dining"
          ? "dining"
          : e.currentTarget.attributes["type"].value)
    );
  }

  async changeLang(val, id) {
    await this.setState({
      lang: val,
      lang_id: id,
    });
    this.dataCategory();
    this.dataTable(this.state.category_id, this.state.category);
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          title={
            (this.state.lang == "TH" && "ช้อปปิ้งและเครื่องดื่ม") ||
            (this.state.lang == "AR" && "التسوق وتناول الطعام") ||
            (this.state.lang == "ZH" && "購物和餐飲") ||
            (this.state.lang == "RU" && "Покупки и рестораны") ||
            "SHOPPING & DINING"
          }
          background={'url("../uploads/banner.png") no-repeat center center'}
          method="shop"
        />
        <nav className="scroll-flexbox p-0 center01">
          {this.state.categorys.map((data, i) => {
            return (
              <div
                id={data.id}
                category={data.category}
                onClick={this.changeCategory}
                className="item cursor-point"
                style={{
                  flex:
                    "0 0 " +
                    (isMobile
                      ? "auto"
                      : 93 / this.state.categorys.length + "%"),
                  textAlign: "center",
                }}
              >
                <a id={data.id} category={data.category}>
                  <img
                    id={data.id}
                    category={data.category}
                    src={data.img}
                    className="icon-menu-shopping"
                  />
                  <p id={data.id} category={data.category}>
                    {data.name}
                  </p>
                </a>
              </div>
            );
          })}
        </nav>
        <div className="main main-detail mt-3">
          <div className="container">
            <div className="input-search ml-3 mr-3">
              <form action="" method="GET">
                <input
                  type="text"
                  name="s"
                  className="form-control"
                  placeholder="Search"
                  defaultValue={this.state.src}
                />
              </form>
            </div>
            <section>
              <div className="row mt-3">
                {this.state.pages.map((data, i) => {
                  return (
                    <div className="col-6 col-md-4">
                      <div className="card cursor-point">
                        <a
                          onClick={this.shoppingDetail}
                          id={data.shop_id}
                          type={data.type}
                        >
                          <div className="thumbnail home-img-ex">
                            <img
                              src={data.shop_logo}
                              className="img-thumb"
                              style={{
                                width: "auto",
                                height: "100%",
                                marginLeft: "50%",
                                transform: "translateX(-50%)",
                              }}
                              alt="..."
                            />
                            <div className="star" style={{ display: "none" }}>
                              <i
                                className="fa fa-star text-gold"
                                aria-hidden="true"
                              />
                              <i
                                className="fa fa-star text-gold"
                                aria-hidden="true"
                              />
                              <i
                                className="fa fa-star text-gold"
                                aria-hidden="true"
                              />
                              <i
                                className="fa fa-star-o text-gold"
                                aria-hidden="true"
                              />
                              <i
                                className="fa fa-star-o text-gold"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </a>
                        <div className="card-body">
                          <h3>
                            <a
                              href={this.shoppingDetail}
                              id={data.shop_id}
                              type={data.type}
                            >
                              {data.shop_name}
                            </a>
                          </h3>
                          {data.shop_terminal && (
                            <span className="text-blue content1line">
                              <i
                                className="fa fa-map-marker"
                                aria-hidden="true"
                              />{" "}
                              {data.shop_terminal}
                            </span>
                          )}
                          <span className="text-blue">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            />{" "}
                            {data.address}
                          </span>
                          {/* <p className="card-text content2line">
                            {data.shop_detail}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
            <nav
              aria-label="Page navigation example"
              style={{ display: "none" }}
            >
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="fa fa-angle-left" aria-hidden="true" />
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
