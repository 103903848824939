import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";

import { Redirect } from "react-router-dom";

const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);
export default class BaggageClaimDetail extends Component {
  constructor(props) {
    super(props);
    const { lang, lang_id } = currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage();
    this.state = {
      lang: lang,
      lang_id: lang_id,
      name: "",
      surname: "",
      passport_type: 0,
      passport_no: "",
      nation: 0,
      province: "",
      city: "",
      zipcode: "",
      mobile_number: "",
      email: "",
      date_lost: "",
      area_lost: "",
      detail_area: "",
      nations: [],
    };
    this.changeLang = this.changeLang.bind(this);
    this.change = this.change.bind(this);
    this.submitAdd = this.submitAdd.bind(this);
    this.dataNation = this.dataNation.bind(this);

    this.dataNation();
  }

  dataNation() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Language: this.state.lang_id,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    fetch(config.url.API_URL + "/getcountry?sort_name=1", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              name: datas.country_name,
            };
          });
          this.setState({
            nations: [].concat(FromApi),
          });
        }
      });
  }

  changeLang(val, id) {
    this.setState({
      lang: val,
      lang_id: id,
    });
  }

  change(e) {
    this.setState({
      [e.target.name]:
        e.target.name === "file1"
          ? e.target.files[0]
          : e.target.name === "date_lost"
          ? e.target.value + "+07:00"
          : e.target.value,
    });
  }

  submitAdd() {
    if (this.state.name == "" || this.state.name == null) {
      alert("Please specify Name information.");
      return;
    }

    if (this.state.surname == "" || this.state.surname == null) {
      alert("Please specify Surname information.");
      return;
    }

    if (this.state.passport_type == 0 || this.state.passport_type == null) {
      alert("Please specify Passport Type information.");
      return;
    }

    if (this.state.passport_no == "" || this.state.passport_no == null) {
      alert("Please specify Passport No or ID Card information.");
      return;
    }

    if (this.state.nation == 0 || this.state.nation == null) {
      alert("Please specify Country information.");
      return;
    }

    if (this.state.province == "" || this.state.province == null) {
      alert("Please specify State information.");
      return;
    }

    if (this.state.city == "" || this.state.city == null) {
      alert("Please specify City information.");
      return;
    }

    if (this.state.zipcode == "" || this.state.zipcode == null) {
      alert("Please specify Zipcode information.");
      return;
    }

    if (this.state.mobile_number == "" || this.state.mobile_number == null) {
      alert("Please specify Mobile Number information.");
      return;
    }

    if (this.state.email == "" || this.state.email == null) {
      alert("Please specify Email information.");
      return;
    }

    if (this.state.date_lost == "" || this.state.date_lost == null) {
      alert("Please specify date lost information.");
      return;
    }

    if (this.state.area_lost == "" || this.state.area_lost == null) {
      alert("Please specify area lost information.");
      return;
    }

    if (this.state.detail_area == "" || this.state.detail_area == null) {
      alert("Please specify detail area information.");
      return;
    }

    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("surname", this.state.surname);
    formData.append("identification_type", this.state.passport_type);
    formData.append("identification", this.state.passport_no);
    formData.append("country_code", this.state.nation);
    formData.append("state", this.state.province);
    formData.append("city", this.state.city);
    formData.append("zipcode", this.state.zipcode);
    formData.append("phone", this.state.mobile_number);
    formData.append("email", this.state.email);
    formData.append("lost_date", this.state.date_lost);
    formData.append("area_lost", this.state.area_lost);
    formData.append("detail_asset", this.state.detail_area);
    formData.append("img0", this.state.file1);
    formData.append("active_status", this.state.add_active ? 1 : 0);
    formData.append("createby", this.state.create_by);
    formData.append("createby_id", this.state.create_by_id);

    console.log(this.state);

    const requestOptions = {
      method: "POST",
      headers: {
        "Authorization-JWT": this.state.jwt,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
      body: formData,
    };

    fetch(config.url.API_URL + "/lostandfound", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        //this.openModelSuccess("คุณได้เพิ่มรายการเรียบร้อยแล้ว");
        if (this.state.lang == "TH") {
          alert("คุณได้เพิ่มรายการเรียบร้อยแล้ว");
          window.location.href = "/baggage-claim-search";
          //baggage-claim-search
        } else {
          alert("Success");
          window.location.href = "/baggage-claim-search";
          //baggage-claim-search
        }
        //window.location.href = "/";
      });

    this.setState({ addIsOpen: false });
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          heightBar="0px"
          background={
            'url("../uploads/banner.png") no-repeat center center / cover'
          }
          method="baggage-claim"
        />
        <div
          className="main main-background-bag-detail"
          style={{
            height: "auto",
            marginTop: "-195px",
            background:
              'url("../images/background-bag.png") no-repeat center center / cover',
            backgroundAttachment: "fixed",
          }}
        >
          <div className="container">
            <section>
              <div className="row">
                <div className="col-12">
                  <h2 className="text-center text-white mt-4">LOST & FOUND</h2>
                  <div className="form-group">
                    <label className="pb-0">
                      {(this.state.lang == "TH" && <span>ชื่อ</span>) ||
                        (this.state.lang == "AR" && <span>الاسم</span>) ||
                        (this.state.lang == "RU" && <span>Имя</span>) ||
                        (this.state.lang == "ZH" && <span>名稱</span>) || (
                          <span>Name</span>
                        )}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="name"
                        onChange={this.change}
                        className="form-control"
                        placeholder={
                          (this.state.lang == "TH" && "ชื่อ") ||
                          (this.state.lang == "AR" && "الاسم") ||
                          (this.state.lang == "RU" && "Имя") ||
                          (this.state.lang == "ZH" && "名稱") ||
                          "Name"
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && "นามสกุล") ||
                      (this.state.lang == "AR" && "اسم العائلة") ||
                      (this.state.lang == "RU" && "Фамилия") ||
                      (this.state.lang == "ZH" && "姓") ||
                      "Surname"}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="surname"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" && "นามสกุล") ||
                        (this.state.lang == "AR" && "اسم العائلة") ||
                        (this.state.lang == "RU" && "Фамилия") ||
                        (this.state.lang == "ZH" && "姓") ||
                        "Surname"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" &&
                      "เลขที่หนังสือเดินทาง / บัตรประจำตัวประชาชน") ||
                      (this.state.lang == "AR" &&
                        "رقم جواز السفر/بطاقة الهوية") ||
                      (this.state.lang == "RU" &&
                        "Номер паспорта / ID-карта") ||
                      (this.state.lang == "ZH" && "護照號碼/身份證") ||
                      "Passport No./ID Card"}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <select
                      className="form-control"
                      name="passport_type"
                      onChange={this.change}
                    >
                      <option value="0">Select</option>
                      <option value="1">
                        {(this.state.lang == "TH" && "เลขที่หนังสือเดินทาง") ||
                          (this.state.lang == "AR" && "رقم جواز السفر") ||
                          (this.state.lang == "RU" && "Номер паспорта.") ||
                          (this.state.lang == "ZH" && "護照號。") ||
                          "Passport No."}
                      </option>
                      <option value="2">
                        {(this.state.lang == "TH" && "เลขที่บัตรประชาชน") ||
                          (this.state.lang == "AR" && "بطاقة الهوية") ||
                          (this.state.lang == "RU" &&
                            "№ удостоверения личности") ||
                          (this.state.lang == "ZH" && "身份證號。") ||
                          "ID Card No."}
                      </option>
                    </select>
                  </div>
                  <div className=" mt-2">
                    <input
                      type="text"
                      name="passport_no"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" &&
                          "เลขที่หนังสือเดินทาง / บัตรประจำตัวประชาชน") ||
                        (this.state.lang == "AR" &&
                          "رقم جواز السفر/بطاقة الهوية") ||
                        (this.state.lang == "RU" &&
                          "Номер паспорта / ID-карта") ||
                        (this.state.lang == "ZH" && "護照號碼/身份證") ||
                        "Passport No./ID Card"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && <span>ที่อยู่</span>) ||
                      (this.state.lang == "AR" && <span>العنوان</span>) ||
                      (this.state.lang == "RU" && <span>Адрес</span>) ||
                      (this.state.lang == "ZH" && <span>地址</span>) || (
                        <span>Address</span>
                      )}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <select
                      className="form-control"
                      name="nation"
                      onChange={this.change}
                    >
                      <option value="">Select</option>
                      {this.state.nations.map((data) => {
                        return <option value={data.name}>{data.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className=" mt-2">
                    <input
                      type="text"
                      name="province"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" &&
                          "รัฐ / จังหวัด / ภูมิภาค") ||
                        (this.state.lang == "AR" &&
                          "الولاية/الإقليم/المنطقة") ||
                        (this.state.lang == "RU" &&
                          "Государство / Провинция / Регион") ||
                        (this.state.lang == "ZH" && "州/省/地區") ||
                        "State/Province/Region"
                      }
                      required
                    />
                  </div>
                  <div className=" mt-2">
                    <input
                      type="text"
                      name="city"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" &&
                          "รัฐ / จังหวัด / ภูมิภาค") ||
                        (this.state.lang == "AR" &&
                          "الولاية/الإقليم/المنطقة") ||
                        (this.state.lang == "RU" &&
                          "Государство / Провинция / Регион") ||
                        (this.state.lang == "ZH" && "州/省/地區") ||
                        "City/Town"
                      }
                      required
                    />
                  </div>
                  <div className=" mt-2">
                    <input
                      type="text"
                      name="zipcode"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" &&
                          "ไปรษณีย์ / รหัสไปรษณีย์") ||
                        (this.state.lang == "AR" &&
                          "الرقم البريدي/الرمز البريدي") ||
                        (this.state.lang == "RU" &&
                          "Почтовый индекс / почтовый индекс") ||
                        (this.state.lang == "ZH" && "郵政編碼") ||
                        "ZIP/Post Code"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && <span>เบอร์มือถือ</span>) ||
                      (this.state.lang == "AR" && <span>رقم الجوال</span>) ||
                      (this.state.lang == "RU" && (
                        <span>Номер мобильного</span>
                      )) ||
                      (this.state.lang == "ZH" && <span>手機號碼</span>) || (
                        <span>Mobile Number</span>
                      )}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="mobile_number"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" && "เบอร์มือถือ") ||
                        (this.state.lang == "AR" && "رقم الجوال") ||
                        (this.state.lang == "RU" && "Номер мобильного") ||
                        (this.state.lang == "ZH" && "手機號碼") ||
                        "Mobile Number"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && <span>อีเมล์</span>) ||
                      (this.state.lang == "AR" && (
                        <span>البريد الإلكتروني</span>
                      )) ||
                      (this.state.lang == "RU" && (
                        <span>Электронное письмо</span>
                      )) ||
                      (this.state.lang == "ZH" && <span>電子郵件</span>) || (
                        <span>Email</span>
                      )}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="email"
                      name="email"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" && "อีเมล์") ||
                        (this.state.lang == "AR" && "البريد الإلكتروني") ||
                        (this.state.lang == "RU" && "Электронное письмо") ||
                        (this.state.lang == "ZH" && "電子郵件") ||
                        "Email"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && (
                      <span>วันที่ / เวลาที่หายไป</span>
                    )) ||
                      (this.state.lang == "AR" && (
                        <span>تاريخ/وقت الفقدان</span>
                      )) ||
                      (this.state.lang == "RU" && (
                        <span>Дата / время потеряно</span>
                      )) ||
                      (this.state.lang == "ZH" && (
                        <span>日期/時間丟失</span>
                      )) || <span>Date/Time lost</span>}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="datetime-local"
                      name="date_lost"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" && "วันที่ / เวลาที่หายไป") ||
                        (this.state.lang == "AR" && "تاريخ/وقت الفقدان") ||
                        (this.state.lang == "RU" && "Дата / время потеряно") ||
                        (this.state.lang == "ZH" && "日期/時間丟失") ||
                        "Date/Time lost"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && <span>พื้นที่สูญหาย</span>) ||
                      (this.state.lang == "AR" && <span>منطقة الفقدان</span>) ||
                      (this.state.lang == "RU" && (
                        <span>Потерянная площадь</span>
                      )) ||
                      (this.state.lang == "ZH" && <span>失地</span>) || (
                        <span>Area lost</span>
                      )}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="area_lost"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" && "พื้นที่สูญหาย") ||
                        (this.state.lang == "AR" && "منطقة الفقدان") ||
                        (this.state.lang == "RU" && "Потерянная площадь") ||
                        (this.state.lang == "ZH" && "失地") ||
                        "Area lost"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && (
                      <span>รายละเอียดทรัพย์สิน</span>
                    )) ||
                      (this.state.lang == "AR" && <span>تفاصيل الأصل</span>) ||
                      (this.state.lang == "RU" && (
                        <span>Подробная область</span>
                      )) ||
                      (this.state.lang == "ZH" && <span>詳細區域</span>) || (
                        <span>Detail Asset</span>
                      )}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="detail_area"
                      onChange={this.change}
                      className="form-control"
                      placeholder={
                        (this.state.lang == "TH" && "รายละเอียดทรัพย์สิน") ||
                        (this.state.lang == "AR" && "تفاصيل الأصل") ||
                        (this.state.lang == "RU" && "Детальный актив") ||
                        (this.state.lang == "ZH" && "詳細資產") ||
                        "Detail Asset"
                      }
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="pb-0">
                    {(this.state.lang == "TH" && (
                      <span>รูปภาพเนื้อหาของคุณ</span>
                    )) ||
                      (this.state.lang == "AR" && (
                        <span>صورة الأصل الخاصة بك</span>
                      )) ||
                      (this.state.lang == "RU" && (
                        <span>Фото вашего объекта</span>
                      )) ||
                      (this.state.lang == "ZH" && (
                        <span>您的資產照片</span>
                      )) || <span>Your Asset Photo</span>}
                  </label>
                  <div className="">
                    <input
                      type="file"
                      name="file1"
                      onChange={this.change}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group mt-5">
                  <button
                    className="btn-blue form-control text-white"
                    style={{ borderRadius: "20px" }}
                    onClick={this.submitAdd}
                  >
                    {(this.state.lang == "TH" && <span>ส่ง</span>) ||
                      (this.state.lang == "AR" && <span>إرسال</span>) ||
                      (this.state.lang == "RU" && <span>ОТПРАВИТЬ</span>) ||
                      (this.state.lang == "ZH" && <span>發送</span>) || (
                        <span>SEND</span>
                      )}
                  </button>
                </div>
              </div>
            </section>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
