import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./component/Login";
import Home from "./component/Home";
import Hotel from "./component/Hotel";
import HotelDetail from "./component/HotelDetail";
import Travel from "./component/Travel.js";
import TravelDetail from "./component/TravelDetail";
import Dinning from "./component/Dinning";
import DinningDetail from "./component/DinningDetail";
import Shopping from "./component/Shopping";
import ShoppingDetail from "./component/ShoppingDetail";
import Transportation from "./component/Transportation";
import TransportationDetail from "./component/TransportationDetail";
import Events from "./component/Events";
import EventsDetail from "./component/EventsDetail";
import FlightInfo from "./component/FlightInfo";
import FlightInfoDetail from "./component/FlightInfoDetail";
import AirportServices from "./component/AirportServices";
import AirportServicesDetail from "./component/AirportServicesDetail";
import BaggageClaim from "./component/BaggageClaim";
import BaggageClaimDetail from "./component/BaggageClaimDetail";
import BaggageClaimSearch from "./component/BaggageClaimSearch";
import BaggageClaimMenu from "./component/BaggageClaimMenu";
import BaggageClaimInfo from "./component/BaggageClaimInfo";
import Promotion from "./component/Promotion";
import Search from "./component/Search";
import FlightSearch from "./component/FlightSearch";
import FormCovid from "./component/FormCovid";
import ContactUs from "./component/ContactUs";
import AboutUs from "./component/AboutUs";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/hotel" component={Hotel} />
        <Route
          path="/hotel-detail/:id"
          render={(props) => {
            return <HotelDetail id={props.match.params.id} />;
          }}
        />
        <Route path="/travel" component={Travel} />
        <Route
          path="/travel-detail/:id"
          render={(props) => {
            return <TravelDetail id={props.match.params.id} />;
          }}
        />
        <Route path="/dinning" component={Dinning} />
        <Route
          path="/dinning-detail/:id/:type"
          render={(props) => {
            return (
              <DinningDetail
                id={props.match.params.id}
                type={props.match.params.type}
              />
            );
          }}
        />
        <Route path="/shopping" component={Shopping} />
        <Route
          path="/shopping-detail/:id/:type"
          render={(props) => {
            return (
              <ShoppingDetail
                id={props.match.params.id}
                type={props.match.params.type}
              />
            );
          }}
        />
        <Route path="/transportation" component={Transportation} />
        <Route
          path="/transportation-detail/:id"
          render={(props) => {
            return <TransportationDetail id={props.match.params.id} />;
          }}
        />
        <Route path="/events" component={Events} />
        <Route
          path="/event-detail/:id"
          render={(props) => {
            return <EventsDetail id={props.match.params.id} />;
          }}
        />
        <Route path="/flight-info" component={FlightInfo} />
        <Route
          path="/flight-info-detail/:id"
          render={(props) => {
            return <FlightInfoDetail id={props.match.params.id} />;
          }}
        />
        <Route path="/airport-services" component={AirportServices} />
        <Route
          path="/airport-services-detail/:id"
          render={(props) => {
            return <AirportServicesDetail id={props.match.params.id} />;
          }}
        />
        <Route
          path="/baggage-claim/:id"
          render={(props) => {
            return <BaggageClaim id={props.match.params.id} />;
          }}
        />
        <Route
          path="/baggage-claim-detail/"
          render={(props) => {
            return <BaggageClaimDetail id={props.match.params.id} />;
          }}
        />
        <Route path="/baggage-claim-search" component={BaggageClaimSearch} />
        <Route path="/baggage-claim-menu" component={BaggageClaimMenu} />
        <Route path="/baggage-claim-info" component={BaggageClaimInfo} />
        <Route
          path="/promotion/:html"
          render={(props) => {
            return <Promotion html={props.match.params.html} />;
          }}
        />
        <Route path="/search" component={Search} />
        <Route path="/flight-search" component={FlightSearch} />
        <Route path="/form-covid" component={FormCovid} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
