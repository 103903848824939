import React, { Component} from 'react';
import { config } from '../Constants';
import { encode } from 'base-64';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
export default class FormCovid extends Component{

    constructor(props){
        super(props);
        const search = this.props.location.search;

        this.state = {
            member_id: new URLSearchParams(search).get("member_id"),
            jwt: new URLSearchParams(search).get("jwt"),
            myflights: [],
            agreeIsOpen: false,
            submitForm: false,
            first_name: '',
            last_name: '',
            passport: '',
            phone_number: '',
            travel_date: '',
            flight_number: '',
            ground_handing: '',
            agree:'',
            alert_agree:false,
            alert_first_name:false,
            alert_last_name:false,
            alert_passport:false,
            alert_phone_number:false,
            alert_travel_date:false,
            alert_flight_number:false,
            alert_ground_handing:false,
        }

        this.myFlight = this.myFlight.bind(this);
        this.myFlight = this.myProfile.bind(this);
        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
        this.previous = this.previous.bind(this);
        this.closeAgree = this.closeAgree.bind(this);
        this.myProfile();
        this.myFlight();
        
    }

    previous() {
        this.setState({
            first_name: '',
            last_name: '',
            passport: '',
            phone_number: '',
            travel_date: '',
            flight_number: '',
            ground_handing: '',
            agree:'',
            submitForm: false
        });
    }

    myProfile() {
        const requestOptions = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization-JWT': this.state.jwt,
                'Authorization': 'Basic ' + encode(config.url.USER + ':' + config.url.PASS),
                'Language': this.state.lang_id,
            },
        };

        fetch(config.url.API_URL + '/profile'
            + '?user_id='+this.state.member_id
            , requestOptions)
            .then(res => res.json())
            .then(data => {
                var result = data.data
                    this.setState({
                        first_name: result.name,
                        last_name: result.surname,
                        phone_number: result.phone,
                    });
                
            });
    }

    myFlight() {

        const requestOptions = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization-JWT': this.state.jwt,
                'Authorization': 'Basic ' + encode(config.url.USER + ':' + config.url.PASS),
                'Language': this.state.lang_id,
            },
        };

        fetch(config.url.API_URL + '/getmyflights'
            + '?user_id='+this.state.member_id
            , requestOptions)
            .then(res => res.json())
            .then(data => {
                if (Array.isArray(data.data)) {
                    var FromApi = data.data.map(datas => {
                        return {
                            flight_id: datas.flight_id,
                            flight_code: datas.flight_code,
                            flight_number: datas.flight_number,
                        }
                    });

                    this.setState({
                        myflights: [].concat(FromApi),
                    });
                }
            });
    }

    change(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    
    submit() {

            if(this.state.first_name == ""){
                this.setState({
                    agreeIsOpen: true,
                    alert_first_name:true
                });
                return false;
            }else if(this.state.last_name == ""){
                this.setState({
                    agreeIsOpen: true,
                    alert_last_name:true
                });
                return false;
            }else if(this.state.passport == ""){
                this.setState({
                    agreeIsOpen: true,
                    alert_passport:true
                });
                return false;
            }else if(this.state.phone_number == ""){
                this.setState({
                    agreeIsOpen: true,
                    alert_phone_number:true
                });
                return false;
            }else if(this.state.travel_date == ""){
                this.setState({
                    agreeIsOpen: true,
                    alert_travel_date:true
                });
                return false;
            }else if(this.state.flight_number == ""){
                this.setState({
                    agreeIsOpen: true,
                    alert_flight_number:true
                });
                return false;
            }else if(this.state.agree == ""){
                this.setState({
                    alert_agree: true,
                    agreeIsOpen: true
                });
                return false;
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization-JWT': this.state.jwt,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encode(config.url.USER+':'+config.url.PASS)
                },
                body: JSON.stringify({
                    user_id: this.state.member_id,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    passport: this.state.passport,
                    phone_number: this.state.phone_number,
                    travel_date: this.state.travel_date,
                    flight_number: this.state.flight_number,
                    ground_handing: this.state.ground_handing,
                }),
            };

            fetch(config.url.API_URL + '/covids', requestOptions)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        submitForm: true
                    });
            });
    }

    closeAgree(){
        this.setState({
            agreeIsOpen: false,
            alert_first_name:false,
            alert_last_name:false,
            alert_passport:false,
            alert_phone_number:false,
            alert_travel_date:false,
            alert_flight_number:false,
            alert_ground_handing:false,
        });
    }

    render(){
        //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
      return (
        <div className="form-covid">
            { this.state.submitForm == true &&
                (<div className="row">
                    <div className="col-12 bg-red">
                        <h1 className="mt-3 text-white pt-3">*กรุณาเก็บหน้านี้ไว้แสดงกับเจ้าหน้าที่เพื่อยืนยันการกรอกข้อมูล</h1>
                        <h1 className="text-white pb-3">*Please keep this page and show it to the staff to verify infomation</h1>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="form-group">
                            <label className="col-12 text-black">ชื่อ / First Name</label>
                            <label className="col-12 text-black">{this.state.first_name+' '+this.state.last_name}</label>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="form-group">
                            <label className="col-12 text-black">หมายเลขบัตรประชาชนหรือหมายเลขพาสปอร์ต / Citizen ID or Passport No.</label>
                            <label className="col-12 text-black">{this.state.passport}</label>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="form-group">
                            <label className="col-12 text-black">เบอร์โทรศัพท์ / Phone number</label>
                            <label className="col-12 text-black">{this.state.phone_number}</label>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="form-group">
                            <label className="col-12 text-black">วันที่เดินทาง / Travel Date</label>
                            <label className="col-12 text-black">{this.state.travel_date}</label>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="form-group">
                            <label className="col-12 text-black">เที่ยวบิน / Flight No.</label>
                            <label className="col-12 text-black">{this.state.flight_number}</label>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="form-group">
                            <label className="col-12 text-black">บริการภาคพื้นดิน / Ground Handing</label>
                            <label className="col-12 text-black">{this.state.ground_handing}</label>
                            
                        </div>
                    </div>
                    
                    <div className="col-12 mb-3">
                        <img src={"../images/thankyou-covid.png"} className="rounded mx-auto d-block mt-5" width="90%"/>
                    </div>
                    
                <div className="col-12 mt-3 mb-5">
                    <div className="form-group text-center">
                        <button className="btn btn-info" onClick={this.previous}>Previous</button>
                    </div>
                </div>
                </div>)
                ||
                this.state.submitForm == false &&
                (<div className="row mt-5">
                <div className="col-12">
                    <img src={"../images/u-tapao.png"} className="rounded mx-auto d-block" width="300"/>
                    <h1 className="text-center mt-3">แบบสอบถามทางด้านสุขภาพผู้โดยสาร ขาเข้า-ออก ภายในประเทศ ท่าอากาศยานอู่ตะเภา</h1>
                    <h1 className="text-center">Passengers Health Survey for <span className="text-danger">COVID-19</span> monitoring Utapao International Airport</h1>
                </div>
                <div className="col-12 mt-5">
                    <div className="form-group">
                        <label>ชื่อ / First Name</label>
                        <input type="text" name="first_name" className="form-control" placeholder="ชื่อ" onChange={this.change} defaultValue={this.state.first_name}/>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="form-group">
                        <label>นามสกุล / Last Name</label>
                        <input type="text" name="last_name" className="form-control" placeholder="นามสกุล" onChange={this.change} defaultValue={this.state.last_name}/>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="form-group">
                        <label>หมายเลขบัตรประชาชน หรือหมายเลขพาสปอร์ต / Citizen ID or Passport No.</label>
                        <input type="text" name="passport" className="form-control" placeholder="ตัวเลขบัตรประชาชน 13 หลัก" onChange={this.change}/>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="form-group">
                        <label>เบอร์โทรศัพท์มือถือ / Mobile phone number</label>
                        <input type="text" name="phone_number" className="form-control" placeholder="ตัวเลขมือถือ 10 หลัก" onChange={this.change} defaultValue={this.state.phone_number}/>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="form-group">
                        <label>วันที่เดินทาง / The day of travel</label>
                        <input type="date" name="travel_date" className="form-control" placeholder="ตัวเลขมือถือ 10 หลัก" onChange={this.change}/>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="form-group">
                        <label>Flight No.</label>
                        <select className="form-control" name="flight_number" onChange={this.change}>
                            <option>เลือก Flight No.</option>
                            {
                                this.state.myflights.slice(this.state.page).map((data, i) => {
                                    return (<option>{data.flight_number}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="form-group">
                        <label>การบริการภาคพื้นดิน / Ground handing</label>
                        <input type="text" name="ground_handing" className="form-control" placeholder="กรอกเฉพาะไฟท์บินส่วนตัว" onChange={this.change}/>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <p className="text-center">ข้าพเจ้ายอมรับและรับรองว่า ข้อความดังกล่าวเป็นจริง และยอมรับข้อบังคับการบังคับใช้</p>
                    <p className="text-center">I agree and cerify that the staement is true and accept the regulations and enforcement.</p>
                    <img src={"../images/logo_web.png"} className="rounded mx-auto d-block mt-5" width="250"/>
                </div>
                <div className="col-12 mt-5">
                    <div className="form-group text-center">
                        <input type="radio" name="agree" value="agree" onChange={this.change}/> Agree
                    </div>
                </div>
                <div className="col-12 mt-3 mb-5">
                    <div className="form-group text-center">
                        <button className="btn btn-success" onClick={this.submit}>Submit</button>
                    </div>
                </div>
            </div>)
            }

            <Modal show={this.state.agreeIsOpen} onHide={this.closeAgree}>
                    <ModalHeader>
                        <ModalTitle>ผลการตรวจสอบ</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        { 
                        this.state.alert_first_name == true && (<h4>กรุณาระบุข้อมูล<span className="text-danger">ชื่อ</span> ก่อนบันทึกรายการ</h4>)
                        || 
                        this.state.alert_last_name == true && (<h4>กรุณาระบุข้อมูล<span className="text-danger">นามสกุล</span> ก่อนบันทึกรายการ</h4>)
                        || 
                        this.state.alert_passport == true && (<h4>กรุณาระบุข้อมูล<span className="text-danger">หมายเลขบัตรประชาชนหรือหมายเลขพาสปอร์ต</span> ก่อนบันทึกรายการ</h4>)
                        || 
                        this.state.alert_phone_number == true && (<h4>กรุณาระบุข้อมูล<span className="text-danger">เบอร์โทรศัพท์มือถือ</span> ก่อนบันทึกรายการ</h4>)
                        || 
                        this.state.alert_travel_date == true && (<h4>กรุณาระบุข้อมูล<span className="text-danger">วันที่เดินทาง</span> ก่อนบันทึกรายการ</h4>)
                        || 
                        this.state.alert_flight_number == true && (<h4>กรุณาระบุข้อมูล<span className="text-danger">Flight No.</span> ก่อนบันทึกรายการ</h4>)
                        || 
                        this.state.alert_ground_handing == true && (<h4>กรุณาระบุข้อมูล<span className="text-danger">การบริการภาคพื้นดิน</span> ก่อนบันทึกรายการ</h4>)
                        || 
                        this.state.alert_agree == true && (<h4>กรุณากดยอมรับข้อบังคับก่อนบันทึกรายการ</h4>)
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.closeAgree}>ตกลง</button>
                    </ModalFooter>
                </Modal>
        </div>
    );
    }
  };