import React, { Component} from 'react';
import BarCover from "./BarCover";
import { config } from '../Constants';
import { encode } from 'base-64';

import { authentication } from '../services/Authentication';
import { BehaviorSubject } from 'rxjs';
const currentLanguage = new BehaviorSubject(JSON.parse(localStorage.getItem('language')));
export default class EventsDetail extends Component{

    constructor(props){
        super(props);
        this.state = {
            name: '',
            detail: '',
            img: '',
            img_cover: '',
            sort: '',
            address: '',
            email: '',
            id: ''
        }
        this.dataTable = this.dataTable.bind(this);
        this.changeLang = this.changeLang.bind(this);
        this.dataTable();
    }

    async componentWillMount(){
        const { lang, lang_id } = await (currentLanguage.value?currentLanguage.value:authentication.getLanguage());

        this.setState({
            lang:lang,
            lang_id: lang_id,
        })

        this.dataTable();
    }

    dataTable() {
        const requestOptions = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encode(config.url.USER + ':' + config.url.PASS),
                'Language': this.state.lang_id,
            },
        };

        fetch(config.url.API_URL + '/getevents/'+ this.props.id + '?'
            , requestOptions)
            .then(res => res.json())
            .then(data => {
                if (Array.isArray(data.data)) {
                    var datas = data.data[0];
                    this.setState({
                        id: datas.event_id,
                        name: datas.event_title,
                        detail: datas.event_detail,
                        img: datas.event_img,
                        img_cover: datas.event_img_cover,
                        open: datas.event_date,
                        terminal: datas.event_terminal,
                        work_day: datas.event_date,
                        telephone: datas.event_telephone,
                    });
                }
            });
    }

    changeLang(val, id){
        this.setState({
            lang: val,
            lang_id: id
        })
    }
    render(){
      return (
        <div>
            <BarCover onChageLangSubmit={this.changeLang} background = {(this.state.img?'url("'+this.state.img+'") no-repeat center center':'url("../uploads/banner.png") no-repeat center center')} method=""/>
            <div className="main main-detail">
                <div className="container">
                <article>
                    <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <div className="mt-sub90">
                        <div className="card-body">
                                <div className="logo">
                                <div className="logo-hotel logo-shopping">
                                    <img src={this.state.img_cover}/>
                                </div>
                                </div>
                            <h2 className="text-center mt-4">{this.state.name}</h2>
                            <p className="card-text p-2 detail">{this.state.detail}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </article>
                <article>
                    <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <h2 className="m-3">NEAREST FOR YOU</h2>
                        <div className="card" style={{minHeight:"35px"}}>
                            <div className="card-body p-3">
                                    <h3 className="mb-2">{this.state.name}</h3>
                                    <p className="text-blue mb-2"><i className="fa fa-map-marker" aria-hidden="true" /> { this.state.address }</p>
                                    <p className="mb-2"><i className="fa fa-clock-o" aria-hidden="true" /> { this.state.work_day }</p>
                                    <p className="mb-2"><i className="fa fa-phone" aria-hidden="true" /> { this.state.telephone }</p>
                            </div>
                            <div className="card-footer p-0" style={{display:"none"}}>
                                <div className="row">
                                    <div className="col text-center bg-blue p-2">
                                        <img src="icon/icon-map.png" width="20" height="auto" style={{marginTop:"-10px"}}/><span className="text-white"> GETTING HERE</span>
                                    </div>
                                    <div className="col text-center bg-darkblue p-2">
                                        <img src="icon/icon-clock.png" width="20" height="auto" style={{marginTop:"-5px"}}/><span className="text-white"> BOOKING</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </article>
                </div>
                <footer />
            </div>
        </div>
    );
    }
  };
