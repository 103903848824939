import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";
import { isMobile } from "react-device-detect";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languages: [],
      pages: [],
      categorys: [],
      type: "",
    };

    this.clickTab1 = this.clickTab1.bind(this);
    this.clickTab2 = this.clickTab2.bind(this);
    this.eventsDetail = this.eventsDetail.bind(this);
    this.dataTable = this.dataTable.bind(this);
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable();
  }

  dataTable(type) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Language: this.state.lang_id,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
    };

    fetch(
      config.url.API_URL + "/getevents" + "?type=" + (type ? type : "event"),
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              id: datas.event_id,
              title: datas.event_title,
              content: datas.event_content,
              img: datas.event_img,
              type: datas.event_type,
              date: datas.event_date,
              distant: datas.distant,
              terminal: datas.event_terminal,
            };
          });
          this.setState({
            pages: [].concat(FromApi),
            srcIsOpen: false,
          });
        }
      });
  }

  clickTab1() {
    document.getElementById("events-menu").style.background =
      "url(icon/tab-1.png) no-repeat center top";
    document.getElementById("events-menu").style.backgroundSize = "cover";

    if (isMobile) {
      document.getElementById("events-menu").style.height = "80px";
    } else {
      document.getElementById("events-menu").style.height = "180px";
    }

    document.getElementById("events-menu1-text").style.borderBottom =
      "2px #29ABE2 solid";
    document.getElementById("events-menu1-text").style.color = "#29ABE2";
    document.getElementById("events-menu1-text").style.fontWeight = "bold";

    document.getElementById("events-menu2-text").style.borderBottom = "none";
    document.getElementById("events-menu2-text").style.color = "#A7A6AB";

    this.setState({
      type: "event",
    });
    this.dataTable("event");
  }

  clickTab2() {
    document.getElementById("events-menu").style.background =
      "url(icon/tab-2.png) no-repeat center top";
    document.getElementById("events-menu").style.backgroundSize = "cover";

    if (isMobile) {
      document.getElementById("events-menu").style.height = "80px";
    } else {
      document.getElementById("events-menu").style.height = "180px";
    }

    //document.getElementById("events-menu1-text").style.textDecoration = "none";
    //document.getElementById("events-menu2-text").style.textDecoration = "underline";
    document.getElementById("events-menu2-text").style.borderBottom =
      "2px #29ABE2 solid";
    document.getElementById("events-menu2-text").style.color = "#29ABE2";
    document.getElementById("events-menu2-text").style.fontWeight = "bold";

    document.getElementById("events-menu1-text").style.borderBottom = "none";
    document.getElementById("events-menu1-text").style.color = "#A7A6AB";

    this.setState({
      type: "news",
    });
    this.dataTable("news");
  }

  showBar() {
    return (
      <div className="container" style={{ clear: "both" }}>
        <div className="row mb-6 events-menu" id="events-menu">
          <a
            className="btn-departures col events-menu1"
            id="events-menu1"
            onClick={this.clickTab1}
            style={{ paddingTop: "35px" }}
          >
            <div className="text-center mt-1" style={{ fontSize: "18px" }}>
              {(this.state.lang == "TH" && (
                <span id="events-menu1-text">กิจกรรม</span>
              )) ||
                (this.state.lang == "AR" && (
                  <span id="events-menu1-text">أحداث</span>
                )) ||
                (this.state.lang == "RU" && (
                  <span id="events-menu1-text">События</span>
                )) ||
                (this.state.lang == "ZH" && (
                  <span id="events-menu1-text">出发</span>
                )) || <span>Events</span>}
            </div>
          </a>
          <a
            className="btn-arrivals col events-menu2"
            id="events-menu2"
            onClick={this.clickTab2}
            style={{ paddingTop: "35px" }}
          >
            <div className="text-center mt-1" style={{ fontSize: "18px" }}>
              {(this.state.lang == "TH" && (
                <span id="events-menu2-text">ข่าวสาร</span>
              )) ||
                (this.state.lang == "AR" && (
                  <span id="events-menu2-text">أخبار</span>
                )) ||
                (this.state.lang == "RU" && (
                  <span id="events-menu2-text">сообщение</span>
                )) ||
                (this.state.lang == "ZH" && (
                  <span id="events-menu2-text">信息</span>
                )) || <span>News</span>}
            </div>
          </a>
        </div>
      </div>
    );
  }

  eventsDetail(e) {
    this.props.history.push(
      "/event-detail/" + e.currentTarget.attributes["id"].value + "?"
    );
  }

  async changeLang(val, id) {
    await this.setState({
      lang: val,
      lang_id: id,
    });
    this.dataTable(this.state.type);
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          background={'url("uploads/banner.png") no-repeat center center'}
          title="NEWS & EVENTS"
          showBar={this.showBar()}
          heightBar="120px"
          method="event"
        />
        <div className="main">
          <div className="container">
            <section>
              <div className="row">
                {this.state.pages.map((data, i) => {
                  return (
                    <div className="col-6 col-md-4">
                      <div className="card cursor-point">
                        <a onClick={this.eventsDetail} id={data.id}>
                          <div className="thumbnail home-img-ex">
                            <img
                              src={data.img}
                              className="img-thumb"
                              alt="..."
                            />
                            {/* <div className="star">
                                                <i className="fa fa-star text-gold" aria-hidden="true" />
                                                <i className="fa fa-star text-gold" aria-hidden="true" />
                                                <i className="fa fa-star text-gold" aria-hidden="true" />
                                                <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                                <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                                </div> */}
                          </div>
                        </a>
                        <div className="card-body">
                          <h3 className="content1line">
                            <a onClick={this.eventsDetail} id={data.id}>
                              {data.title}
                            </a>
                          </h3>
                          <span className="text-blue content1line">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            />{" "}
                            {data.terminal ? data.terminal : "0.0"}
                          </span>
                          <p className="card-text content2line">
                            {data.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
            {/* <nav aria-label="Page navigation example">
                    <ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true" /></a></li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true" /></a></li>
                    </ul>
                </nav> */}
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
