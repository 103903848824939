import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);
export default class ShoppingDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      detail: "",
      img: "",
      img_cover: "",
      sort: "",
      pin: "",
      floor: "",
      open: "",
      address: "",
      telephone: "",
      email: "",
      id: "",
    };

    this.dataTable = this.dataTable.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.dataTable();
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable();
  }

  dataTable() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    fetch(
      config.url.API_URL +
        "/customerservice/" +
        this.props.id +
        "?is_type=" +
        this.props.type,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          var datas = data.data[0];
          this.setState({
            id: datas.is_id,
            name: datas.is_name,
            detail: datas.is_detail,
            img: datas.is_img,
            img_cover: datas.is_img_cover,
            sort: datas.is_sort,
            pin: datas.is_pin,
            floor: datas.is_floor,
            open: datas.is_open,
            address: datas.is_address,
            telephone: datas.is_telephone,
            email: datas.is_email,
            terminal: datas.is_terminal,
            work_day: datas.is_work_day,
          });
        }
      });
  }

  changeLang(val, id) {
    this.setState({
      lang: val,
      lang_id: id,
    });
  }

  render() {
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          background={
            this.state.img
              ? 'url("' + this.state.img + '") no-repeat center center'
              : 'url("../uploads/banner.png") no-repeat center center'
          }
          method=""
        />
        <div className="main main-detail">
          <div className="container">
            <article>
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="mt-sub90 overflow-visible mb-3">
                    <div className="card-body">
                      <div className="logo">
                        <div className="logo-hotel logo-shopping">
                          <img src={this.state.img_cover} />
                        </div>
                      </div>
                      <h2 className="text-center mt-4">{this.state.name}</h2>
                      <p className="card-text p-2 detail">
                        {this.state.detail}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article>
              <h3>
                {(this.state.lang == "TH" && (
                  <span>ใกล้ที่สุดสำหรับคุณ</span>
                )) ||
                  (this.state.lang == "AR" && <span>الأقرب لك</span>) ||
                  (this.state.lang == "RU" && <span>БЛИЖАЙШИЙ ДЛЯ ВАС</span>) ||
                  (this.state.lang == "ZH" && <span>最適合您</span>) || (
                    <span>NEAREST FOR YOU</span>
                  )}
              </h3>
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="card" style={{ minHeight: "35px" }}>
                    <div className="card-body p-4">
                      <h3 className="mb-2">{this.state.name}</h3>
                      <p className="text-blue mb-2">
                        <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                        {this.state.address}
                      </p>
                      <p className="mb-2">
                        <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                        {this.state.work_day}
                      </p>
                    </div>
                    <div
                      className="card-footer p-0"
                      style={{ display: "none" }}
                    >
                      <div className="row">
                        <div className="col text-center bg-blue p-2">
                          <img
                            src={"../icon/icon-map.png"}
                            width="20"
                            height="auto"
                            style={{ marginTop: "-10px" }}
                          />
                          <span className="text-white"> GETTING HERE</span>
                        </div>
                        <div className="col text-center bg-darkblue p-2">
                          <img
                            src={"../icon/icon-clock.png"}
                            width="20"
                            height="auto"
                            style={{ marginTop: "-5px" }}
                          />
                          <span className="text-white"> BOOKING</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
