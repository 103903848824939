import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";

import { isMobile } from "react-device-detect";
import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class AirportServices extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const src = params.get("s");

    this.state = {
      datas: [],
      categorys: [],
      src: src ? src : "",
    };

    this.dataTable = this.dataTable.bind(this);
    this.dataDetail = this.dataDetail.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.dataCategory = this.dataCategory.bind(this);
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable();
    this.dataCategory();
  }

  dataTable(category_id) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    this.setState({
      category_id: category_id,
    });

    fetch(
      config.url.API_URL +
        "/customerservice?category_cat=service&category_id=" +
        (category_id && category_id != "service" ? category_id : "") +
        (this.state.src != null && "&keyword=" + this.state.src),
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          var FromApi = data.data.map((datas) => {
            return {
              id: datas.is_id,
              title: datas.is_name,
              content: datas.is_detail.substring(0, 50),
              img: datas.is_img,
              type: datas.is_type,
              latitude: datas.is_latitude,
              longitude: datas.is_longitude,
              is_address: datas.is_address,
            };
          });

          this.setState({
            datas: [].concat(FromApi),
          });
        }
      });
  }

  async changeCategory(e) {
    var elems = document.querySelectorAll(".item");

    [].forEach.call(elems, function (el) {
      el.classList.remove("item-active");
    });
    this.dataTable(e.currentTarget.attributes["id"].value);
    const id = await e.target.id;
    document.getElementById(id).classList.add("item-active");
  }

  dataCategory() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Language: this.state.lang_id,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    fetch(
      config.url.API_URL + "/customerservicecategory" + "?type=service",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              id: datas.category_id,
              name: datas.category_name,
              detail: datas.category_detail,
              img: datas.category_img,
            };
          });
          this.setState({
            categorys: [].concat(FromApi),
            srcIsOpen: false,
          });
        }
      });
  }

  dataDetail(e) {
    this.props.history.push(
      "/airport-services-detail/" + e.currentTarget.attributes["id"].value
    );
  }

  async changeLang(val, id) {
    await this.setState({
      lang: val,
      lang_id: id,
    });
    this.dataCategory();
    this.dataTable(this.state.category_id);
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          background={'url("uploads/banner.png") no-repeat center center'}
          method="service"
          title={
            (this.state.lang == "TH" && "สนามบินและบริการ") ||
            (this.state.lang == "AR" && "المطار والخدمة") ||
            (this.state.lang == "RU" && "АЭРОПОРТ И УСЛУГИ") ||
            (this.state.lang == "ZH" && "机场和服务") ||
            "AIRPORT & SERVICE"
          }
        />
        <nav className="scroll-flexbox p-0 center01">
          {this.state.categorys.map((data, i) => {
            return (
              <div
                id={data.id}
                onClick={this.changeCategory}
                className="item cursor-point"
                style={{
                  flex:
                    "0 0 " +
                    (isMobile
                      ? "auto"
                      : 93 / this.state.categorys.length + "%"),
                  textAlign: "center",
                }}
              >
                <a id={data.id}>
                  <img
                    id={data.id}
                    src={data.img}
                    className="icon-menu-shopping"
                  />
                  <p id={data.id} className="content1line">
                    {data.name}
                  </p>
                </a>
              </div>
            );
          })}
        </nav>
        <div className="main main-detail mt-3">
          <div className="container">
            <div className="input-search ml-3 mr-3">
              <form action="" method="GET">
                <input
                  type="text"
                  name="s"
                  className="form-control"
                  placeholder="Search"
                  defaultValue={this.state.src}
                />
              </form>
            </div>
            <section>
              {/* <h3><img src="icon/airport-services.png" className="icon-shopping"/>AIRPORT SERVICES</h3> */}
              <div className="row">
                {this.state.datas.map((data, i) => {
                  return (
                    <div className="col-6 col-md-4">
                      <div className="card cursor-point">
                        <a onClick={this.dataDetail} id={data.id}>
                          <div className="thumbnail home-img-ex">
                            <img
                              src={data.img}
                              className="img-thumb"
                              alt="..."
                            />
                            {/* <div className="star">
                                                            <i className="fa fa-star text-gold" aria-hidden="true" />
                                                            <i className="fa fa-star text-gold" aria-hidden="true" />
                                                            <i className="fa fa-star text-gold" aria-hidden="true" />
                                                            <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                                            <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                                            </div>*/}
                          </div>
                        </a>
                        <div
                          className="card-body"
                          style={{ minHeight: "100px" }}
                        >
                          <h3>
                            <a onClick={this.dataDetail} id={data.id}>
                              {data.title}
                            </a>
                          </h3>
                          <span className="text-blue">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            />
                            {data.is_address ? data.is_address : "-"}
                          </span>
                          <p className="card-text content2line">
                            {data.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
