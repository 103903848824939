import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
import { encode } from "base-64";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class Search extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search;
    this.state = {
      languages: [],
      pages: [],
      search: new URLSearchParams(search).get("s"),
      type: new URLSearchParams(search).get("src_type"),
    };
    this.dataTable = this.dataTable.bind(this);
    this.dataDetail = this.dataDetail.bind(this);
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable();
  }

  dataTable() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Language: this.state.lang_id,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
    };

    fetch(
      config.url.API_URL +
        "/search" +
        "?type=all&flight_sense=A&keyword=" +
        (this.state.search ? this.state.search : ""),
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        //console.log("=======search======"+JSON.stringify(data));
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              src_id: datas.src_id,
              src_name: datas.src_name,
              src_type_id: datas.src_type_id,
              src_flag: datas.src_flag,
              src_img: datas.src_img,
              src_type_name: datas.src_type_name,
            };
          });
          this.setState({
            pages: [].concat(FromApi),
            srcIsOpen: false,
          });
        }
      });
  }

  dataDetail(e) {
    if (e.currentTarget.attributes["type"].value == "flight") {
      this.props.history.push(
        "/flight-info-detail/" + e.currentTarget.attributes["id"].value
      );
    } else if (e.currentTarget.attributes["type"].value == "shop") {
      this.props.history.push(
        "/shopping-detail/" + e.currentTarget.attributes["id"].value + "/shop"
      );
    } else if (e.currentTarget.attributes["type"].value == "dining") {
      this.props.history.push(
        "/shopping-detail/" + e.currentTarget.attributes["id"].value + "/dining"
      );
    } else if (e.currentTarget.attributes["type"].value == "service") {
      this.props.history.push(
        "/airport-services-detail/" +
          e.currentTarget.attributes["id"].value +
          "/service"
      );
    } else if (e.currentTarget.attributes["type"].value == "transports") {
      this.props.history.push(
        "/transportation-detail/" +
          e.currentTarget.attributes["id"].value +
          "/transport"
      );
    } else if (e.currentTarget.attributes["type"].value == "tourist") {
      this.props.history.push(
        "/travel-detail/" + e.currentTarget.attributes["id"].value + "/travel"
      );
    } else if (e.currentTarget.attributes["type"].value == "vatrefund") {
      this.props.history.push(
        "/shopping-detail/" +
          e.currentTarget.attributes["id"].value +
          "/vatrefund"
      );
    } else if (e.currentTarget.attributes["type"].value == "lounge") {
      this.props.history.push(
        "/shopping-detail/" + e.currentTarget.attributes["id"].value + "/lounge"
      );
    }
  }

  changeLang(val, id) {
    this.setState({
      lang: val,
      lang_id: id,
    });
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          background={'url("../uploads/banner.png") no-repeat center center'}
          heightBar="120px"
          method=""
          title="SEARCH"
          src_type={this.state.type}
        />
        <div className="main main-detail">
          <div className="container">
            <section>
              <div className="row">
                {this.state.pages.length > 0 ? (
                  this.state.pages.map((data, i) => {
                    return (
                      (data.src_type_id == "flight" && (
                        <div className="col-xs-12 col-md-12 col-lg-12 cursor-point">
                          <div
                            className="card"
                            onClick={this.dataDetail}
                            type={data.src_type_id}
                            id={data.src_id}
                            style={{ minHeight: "100px" }}
                          >
                            <img
                              src={"../icon/logo-next-black.png"}
                              className="icon-next"
                            />
                            <div className="card-body">
                              <div className="row">
                                <div className="col-3">
                                  <img
                                    src={data.src_img}
                                    width="60%"
                                    height="auto"
                                    style={{ margin: "15px 0 0 20px" }}
                                  />
                                </div>
                                <div className="col-9">
                                  <p className="text-black mt-3 mb-0">
                                    {data.src_name}
                                  </p>
                                  <p className="text-gray mt-0">
                                    {data.src_type_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )) || (
                        <div className="col-xs-12 col-md-12 col-lg-12 cursor-point">
                          <div
                            className="card"
                            onClick={this.dataDetail}
                            type={data.src_type_id}
                            id={data.src_id}
                            style={{ minHeight: "100px" }}
                          >
                            <img
                              src={"../icon/logo-next-black.png"}
                              className="icon-next"
                            />
                            <div className="card-body">
                              <div className="row">
                                <div className="col-4">
                                  <img
                                    src={data.src_img}
                                    className="img-search"
                                    height="auto"
                                    style={{ margin: "10px 0 0 10px" }}
                                  />
                                </div>
                                <div className="col-8">
                                  <p className="text-black mt-3 mb-0 title-search">
                                    {data.src_name}
                                  </p>
                                  <p className="text-gray mt-0 subtitle-search">
                                    {data.src_type_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="col-xs-12 col-md-12 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 text-center">
                            <p className="mt-3 text-gray">Search not found</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
