import React, { Component, useLayoutEffect } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";

import { isMobile } from "react-device-detect";
import DatePicker, { registerLocale } from "react-datepicker";
import { th, ar, ru, zhCN } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import "moment/locale/ar";
import "moment/locale/th";
import "moment/locale/ru";
import "moment/locale/zh-cn";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";

import {
  FlightStatusColors,
  FlightStatusLangs,
} from "../constants/FlightStatus";

registerLocale("th", th);
registerLocale("ar", ar);
registerLocale("ru", ru);
registerLocale("zh", zhCN);

const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class FlightInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languages: [],
      pages: [],
      dateMenuHtml: [],
      category_id: "international",
      flight_type: "domestics",
      startDate: "",
      src_date: new Date(),
      startDate: moment().format("YYYY-MM-DD"),
      active: "active",
      middle: 0,
      lang: "EN",
      locale: "en",
    };

    this.dataTable = this.dataTable.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.clickTab1 = this.clickTab1.bind(this);
    this.clickTab2 = this.clickTab2.bind(this);
    this.showDate = this.showDate.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.scrollDate = this.scrollDate.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    if (lang == "TH") {
      this.setState({
        locale: "th",
      });
      moment.locale("th");
    } else if (lang == "AR") {
      this.setState({
        locale: "ar",
      });
      moment.locale("ar");
    } else if (lang == "EN") {
      this.setState({
        locale: "en",
      });
      moment.locale("en");
    } else if (lang == "RU") {
      this.setState({
        locale: "ru",
      });
      moment.locale("ru");
    } else if (lang == "ZH") {
      this.setState({
        locale: "zh-cn",
      });
      moment.locale("zh-cn");
    }
    this.showDate();
  }

  clickTab1() {
    document.getElementById("events-menu").style.background =
      "url(icon/tab-1.png) no-repeat center top";
    document.getElementById("events-menu").style.backgroundSize = "cover";

    if (isMobile) {
      document.getElementById("events-menu").style.height = "80px";
    } else {
      document.getElementById("events-menu").style.height = "180px";
    }

    document.getElementById("events-menu1-text").style.borderBottom =
      "2px #29ABE2 solid";
    document.getElementById("events-menu1-text").style.color = "#29ABE2";
    document.getElementById("events-menu1-text").style.fontWeight = "bold";

    document.getElementById("events-menu2-text").style.borderBottom = "none";
    document.getElementById("events-menu2-text").style.color = "#A7A6AB";

    this.setState({
      flight_type: "domestics",
    });
    this.dataTable("domestics", this.state.startDate);
  }

  clickTab2() {
    document.getElementById("events-menu").style.background =
      "url(icon/tab-2.png) no-repeat center top";
    document.getElementById("events-menu").style.backgroundSize = "cover";

    if (isMobile) {
      document.getElementById("events-menu").style.height = "80px";
    } else {
      document.getElementById("events-menu").style.height = "180px";
    }

    //document.getElementById("events-menu1-text").style.textDecoration = "none";
    //document.getElementById("events-menu2-text").style.textDecoration = "underline";
    document.getElementById("events-menu2-text").style.borderBottom =
      "2px #29ABE2 solid";
    document.getElementById("events-menu2-text").style.color = "#29ABE2";
    document.getElementById("events-menu2-text").style.fontWeight = "bold";

    document.getElementById("events-menu1-text").style.borderBottom = "none";
    document.getElementById("events-menu1-text").style.color = "#A7A6AB";

    this.setState({
      flight_type: "international",
    });
    this.dataTable("international", this.state.startDate);
  }

  showBar() {
    return (
      <div className="container" style={{ clear: "both" }}>
        <div className="row mb-6 events-menu" id="events-menu">
          <a
            className="btn-departures col events-menu1"
            id="events-menu1"
            onClick={this.clickTab1}
          >
            <div className="text-center mt-1">
              {(this.state.lang == "TH" && (
                <span id="events-menu1-text">สายการบินในประเทศ</span>
              )) ||
                (this.state.lang == "EN" && (
                  <span id="events-menu1-text">Domestics Flight</span>
                )) ||
                (this.state.lang == "RU" && (
                  <span id="events-menu1-text">прислуга полета</span>
                )) ||
                (this.state.lang == "ZH" && (
                  <span id="events-menu1-text">国货航班</span>
                )) || <span id="events-menu1-text">Domestics Flight</span>}
            </div>
          </a>
          <a
            className="btn-arrivals col events-menu2"
            id="events-menu2"
            onClick={this.clickTab2}
          >
            <div className="text-center mt-1">
              {(this.state.lang == "TH" && (
                <span id="events-menu2-text">สายการบินระหว่างประเทศ</span>
              )) ||
                (this.state.lang == "EN" && (
                  <span id="events-menu2-text">International Flight</span>
                )) ||
                (this.state.lang == "RU" && (
                  <span id="events-menu2-text">Международный рейс</span>
                )) ||
                (this.state.lang == "ZH" && (
                  <span id="events-menu2-text">国际航班</span>
                )) || <span id="events-menu2-text">International Flight</span>}
            </div>
          </a>
        </div>
      </div>
    );
  }

  dataTable(flight_type, startDate) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };
    //getflightinfo?flight_type=domestics&flight_sense=D&keyword=&flight_date=2021-12-14
    fetch(
      config.url.API_URL +
        "/getflightinfo" +
        "?flight_type=" +
        (flight_type ? flight_type : this.state.flight_type) +
        "&flight_sense=A&flight_date=" +
        (startDate ? startDate : moment().format("YYYY-MM-DD")),
      //+ '&flight_date='
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          var FromApi = data.data.map((datas) => {
            return {
              flight_id: datas.flight_id,
              flight_code: datas.flight_code,
              flight_number: datas.flight_number,
              flight_sense: datas.flight_sense,
              flight_status: datas.flight_status,
              flight_type: datas.flight_type,
              flight_from_date: datas.flight_from_date,
              flight_from_time: datas.flight_from_time,
              flight_to_date: datas.flight_to_date,
              flight_to_time: datas.flight_to_time,
              flight_boarding: datas.flight_boarding,
              flight_terminal_name: datas.flight_terminal_name,
              flight_airlines_name: datas.flight_airlines_name,
              flight_airlines_short_name: datas.flight_airlines_short_name,
              flight_airlines_logo: datas.flight_airlines_logo,
              flight_location_name: datas.flight_location_name,
              flight_location_short_name: datas.flight_location_short_name,
              flight_destination_name: datas.flight_destination_name,
              flight_destination_short_name:
                datas.flight_destination_short_name,
              flight_counter_number: datas.flight_counter_number,
              flight_checkin_close: datas.flight_checkin_close,
              flight_boarding_gate: datas.flight_boarding_gate,
              flight_gate_close: datas.flight_gate_close,
              flight_seat_no: datas.flight_seat_no,
              flight_seq: datas.flight_seq,
              flight_from_nation: datas.flight_from_nation,
              flight_to_nation: datas.flight_to_nation,
              flight_from_exchange: datas.flight_from_exchange,
              flight_to_exchange: datas.flight_to_exchange,
              flight_from_logo: datas.flight_from_logo,
              flight_to_logo: datas.flight_to_logo,
            };
          });

          this.setState({
            pages: [].concat(FromApi),
            srcIsOpen: false,
          });
        }
      });
  }

  handleDetail(e) {
    //alert(e.currentTarget.attributes["id"].value);
    this.props.history.push(
      "/flight-info-detail/" +
        e.currentTarget.attributes["id"].value +
        "?tab=" +
        this.state.tab
    );
  }

  changeDate(e) {
    this.setState({
      startDate: e.currentTarget.attributes["value"].value,
      src_date: moment(e.currentTarget.attributes["value"].value).toDate(),
    });
    this.dataTable(
      this.state.flight_type,
      e.currentTarget.attributes["value"].value
    );
  }

  showDate() {
    var start = moment().subtract(1, "days");
    var end = moment().add(7, "days");
    var html = [];
    var htmlDesk = [];
    var i = 1;
    for (var m = start; m.isBefore(end); m.add(1, "days")) {
      html.push(
        <div
          id={"block-" + m.format("dddDMMMM")}
          value={m.format("YYYY-MM-DD")}
          index={i - 1}
          onClick={this.scrollDate}
          className={
            "item-date " +
            (this.state.startDate == m.format("YYYY-MM-DD") ? "active" : "")
          }
        >
          <a href="#" value={m.format("YYYY-MM-DD")} onClick={this.changeDate}>
            {m.locale(this.state.locale).format("dddd")},{" "}
            <span>{m.format("DD")}</span>{" "}
            {m.locale(this.state.locale).format("MMMM")}
          </a>
        </div>
      );
      htmlDesk.push(
        <div
          id={"desk-block-" + m.format("dddDMMMM")}
          value={m.format("YYYY-MM-DD")}
          index={i - 1}
          onClick={this.scrollDate}
          className={
            "item-date " +
            (this.state.startDate == m.format("YYYY-MM-DD") ? "active" : "")
          }
        >
          <a href="#" value={m.format("YYYY-MM-DD")} onClick={this.changeDate}>
            {m.locale(this.state.locale).format("dddd")},{" "}
            <span>{m.format("DD")}</span>{" "}
            {m.locale(this.state.locale).format("MMMM")}
          </a>
        </div>
      );
      i++;
    }

    this.setState({
      dateMenuHtml: html,
      dateMenuHtmlDesk: htmlDesk,
    });
  }

  scrollDate(e) {
    this.setState({
      src_date: moment(e.currentTarget.attributes["value"].value).toDate(),
    });
    //document.getElementsByClassName("item-date").classList.remove("active");
    document
      .querySelectorAll(".item-date")
      .forEach((e) => e.classList.remove("active"));
    e.currentTarget.classList.add("active");
    const selectDate = document.getElementById("select-date");
    var selectedItem = document.getElementById(
      e.currentTarget.attributes["id"].value
    );
    var selectedIndex = parseInt(e.currentTarget.attributes["index"].value);
    const elementRect = selectedItem.getBoundingClientRect();
    //const absoluteElementLeft = elementRect.left + window.pageXOffset;
    //const middle = (selectDate.offsetWidth-element.offsetWidth)/2;
    //console.log("obj:"+e.currentTarget.attributes["id"].value+", e.currentTarget.attributes[index].value:"+e.currentTarget.attributes["index"].value);
    var sumWidth = 0;
    for (var i = 1; i <= selectedIndex; i++) {
      var rect = selectDate.childNodes[i].getBoundingClientRect();
      sumWidth += parseInt(rect.width) + 7.5;
    }
    var scrollTo = 0;
    if (selectedIndex > 0) {
      scrollTo = sumWidth - selectedItem.offsetWidth / 2;
    }

    this.setState({ middle: scrollTo });
    selectDate.scrollTo(scrollTo, 0);
  }

  changeLang(lang, id) {
    this.setState({
      lang: lang,
      lang_id: id,
    });

    if (lang == "TH") {
      this.setState({
        locale: "th",
      });
      moment.locale("th");
    } else if (lang == "AR") {
      this.setState({
        locale: "ar",
      });
      moment.locale("ar");
    } else if (lang == "EN") {
      this.setState({
        locale: "en",
      });
      moment.locale("en");
    } else if (lang == "RU") {
      this.setState({
        locale: "ru",
      });
      moment.locale("ru");
    } else if (lang == "ZH") {
      this.setState({
        locale: "zh-cn",
      });
      moment.locale("zh-cn");
    }
    this.showDate();
  }

  changeCalendar = (date) => {
    // console.log(moment(this.state.src_date).format('ddd, D MMMM'));
    // document.getElementById("block-" + this.state.src_date).className = "mystyle";
    this.setState({
      src_date: date,
      startDate: date,
    });

    this.dataTable(this.state.flight_type, moment(date).format("YYYY-MM-DD"));
    try {
      if (window.screen.width < 900) {
        document
          .getElementById(
            "block-" + moment(date).locale(this.state.locale).format("dddDMMMM")
          )
          .click();
      } else {
        document
          .getElementById(
            "desk-block-" +
              moment(date).locale(this.state.locale).format("dddDMMMM")
          )
          .click();
      }
    } catch (error) {}
  };

  componentDidMount() {
    this.dataTable();
    this.showDate();
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          heightBar="0px"
          title={
            (this.state.lang == "TH" && "ข้อมูลเที่ยวบิน") ||
            (this.state.lang == "RU" && "Мой полет") ||
            (this.state.lang == "ZH" && "我的航班") ||
            (this.state.lang == "AR" && "معلومات الرحلة") ||
            "FLIGHTS INFO"
          }
          background={'url("uploads/banner.png") no-repeat center center'}
          method="flight-info"
          showBar={this.showBar()}
          src_type="flight"
        />
        <div className="main">
          <nav
            className="scroll-flexbox d-mob"
            style={{ marginTop: "0px" }}
            id="select-date"
          >
            {this.state.dateMenuHtml}
          </nav>
          <div className="container">
            <nav className="scroll-flexbox d-desk" style={{ marginTop: "0px" }}>
              {this.state.dateMenuHtmlDesk}
            </nav>
            <div className="d-flex justify-content-between bd-highlight mb-3 mt-2">
              <div className="bd-highlight">
                <div className="refresh">
                  <a href="/flight-info">
                    <img src="../icon/refresh.png" class="icon-refresh" />
                  </a>
                </div>
                <div className="text-gray label-date">
                  {" "}
                  Update{" "}
                  {moment()
                    .locale(this.state.locale)
                    .format("DD/MM/YY, HH:mm")}{" "}
                </div>
              </div>
              <div className="bd-highlight">
                <div
                  className="label-calendar cursor-point"
                  style={{ width: "80%", float: "right" }}
                >
                  <DatePicker
                    id="label-calendar"
                    className="cursor-point"
                    dateFormat="dd MMM yyyy"
                    onChange={this.changeCalendar}
                    selected={this.state.src_date}
                    locale={this.state.locale}
                  />
                  <img src="../icon/calendar-16px.png" class="icon-calendar" />
                </div>
              </div>
            </div>
            {this.state.pages.slice(this.state.page).map((data, i) => {
              return (
                <article>
                  <div className="row">
                    <a
                      id={data.flight_id}
                      onClick={this.handleDetail}
                      className="flight-info cursor-point"
                    >
                      <div className="col-xs-12 col-md-12 col-lg-12 col-p0">
                        <div className="card">
                          <div className="card-body pl-5 pr-5 pb-3 pt-3">
                            <div className="row ">
                              <div className="col-12 mb-2 text-center pt-1">
                                <img
                                  src={data.flight_airlines_logo}
                                  className="icon-airline"
                                />{" "}
                                <span className="flight-number">
                                  {data.flight_number}
                                </span>
                              </div>
                              <div className="col-4 col-p0 text-left cart-flight-l">
                                <h3 className="short-takeoff-airport">
                                  {data.flight_location_short_name}
                                </h3>
                                <p className="card-text text-gray takeoff-airport">
                                  {data.flight_location_name}
                                </p>
                              </div>
                              <div className="col-4 col-p0 text-center cart-flight-takeoff">
                                <div className="line"></div>
                                <img
                                  src="../icon/icon-plan.png"
                                  width="40"
                                  height="auto"
                                />
                              </div>
                              <div className="col-4 col-p0 text-right cart-flight-r">
                                <h3 className="short-takeoff-airport">
                                  {data.flight_destination_short_name}
                                </h3>
                                <p className="card-text text-gray takeoff-airport">
                                  {data.flight_destination_name}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4 col-p0 cart-flight-bottom text-left">
                                <p className="mb-0 filght-status">
                                  {data.flight_sense == "A"
                                    ? (this.state.lang == "TH" && (
                                        <span>เวลาเข้า</span>
                                      )) ||
                                      (this.state.lang == "AR" && (
                                        <span>وقت الوصول</span>
                                      )) ||
                                      (this.state.lang == "RU" && (
                                        <span>Время входа</span>
                                      )) ||
                                      (this.state.lang == "ZH" && (
                                        <span>入场时间</span>
                                      )) || <span>Arrival time</span>
                                    : (this.state.lang == "TH" && (
                                        <span>เวลาออกเดินทาง</span>
                                      )) ||
                                      (this.state.lang == "AR" && (
                                        <span>وقت المغادرة</span>
                                      )) ||
                                      (this.state.lang == "RU" && (
                                        <span>Время отправления</span>
                                      )) ||
                                      (this.state.lang == "ZH" && (
                                        <span>出發時間</span>
                                      )) || <span>Depart time</span>}
                                </p>
                                <p className="font-highlight">
                                  {data.flight_sense == "A"
                                    ? moment(data.flight_to_date)
                                        .locale(this.state.locale)
                                        .format("DD MMM YYYY") +
                                      " " +
                                      data.flight_to_time
                                    : moment(data.flight_from_date)
                                        .locale(this.state.locale)
                                        .format("DD MMM YYYY") +
                                      " " +
                                      data.flight_from_time}
                                </p>
                              </div>
                              <div className="col-4 col-p0 cart-flight-bottom">
                                <p className="mb-0 text-center">
                                  {data.flight_sense == "D" && "Check-in"}
                                </p>
                                <p className="font-highlight text-center">
                                  {data.flight_sense == "D" && (
                                    <sapn className="btn-default">
                                      {" "}
                                      {data.flight_counter_number
                                        ? data.flight_counter_number
                                        : "-"}
                                    </sapn>
                                  )}
                                </p>
                              </div>
                              <div className="col-4 col-p0 cart-flight-bottom text-right">
                                <p className="mb-0 filght-status">
                                  {(this.state.lang == "TH" && (
                                    <span>สถานะ</span>
                                  )) ||
                                    (this.state.lang == "AR" && (
                                      <span>الحالة</span>
                                    )) ||
                                    (this.state.lang == "RU" && (
                                      <span>Статус</span>
                                    )) ||
                                    (this.state.lang == "ZH" && (
                                      <span>地位</span>
                                    )) || <span>Status</span>}
                                </p>
                                {/* <p className="font-highlight">
                                                    {(
                                                        data.flight_status.toLowerCase() == "landed" && <sapn className="btn-status flight-status" style={{ background:"#29ABE2", color:"#FFFFFF" }}>{data.flight_status.toUpperCase()}</sapn>
                                                    ||
                                                        data.flight_status.toLowerCase() == "departed" && <sapn className="btn-status flight-status" style={{ background:"#8DC63F", color:"#FFFFFF" }}>{data.flight_status.toUpperCase()}</sapn>
                                                    ||
                                                        data.flight_status.toLowerCase() == "cancelled" && <sapn className="btn-status flight-status" style={{ background:"#FF0000", color:"#FFFFFF" }}>{data.flight_status.toUpperCase()}</sapn>
                                                        )}
                                                        </p> */}
                                <p className="font-highlight">
                                  <sapn
                                    className="btn-status flight-status"
                                    style={{
                                      background:
                                        FlightStatusColors[data.flight_status],
                                      color: "#FFFFFF",
                                    }}
                                  >
                                    {this.state.lang == "TH"
                                      ? FlightStatusLangs[data.flight_status]
                                      : data.flight_status.toUpperCase()}
                                  </sapn>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </article>
              );
            })}
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
