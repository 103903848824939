const dev = {
  url: {
    API_URL: "https://api.utapaoapp.com",
    // API_URL: "http://58.137.160.77:8000",
    USER: "admin_utapao",
    PASS: "1q2w3eqawsed",
  },
  val: {
    LANG: "",
  },
};

export const config = dev;
