const green = "#8DC63E";
const red = "#FF0000";
const blue = "#29ABE2";
const yellow = "#FAC102";

export const FlightStatusLangs = {
  "Confirmed": "ยืนยัน",
  "Check-in Open": "เช็คอินเปิด",
  "Counter Closed": "เช็คอินปิด",
  "Boarding": "ขึ้นเครื่อง",
  "Delayed": "ล่าช้า",
  "Cancelled": "ยกเลิก",
  "Gate Open": "ประตูขึ้นเครื่องเปิด",
  "Gate Close": "ประตูขึ้นเครื่องปิด",
  "Final Call": "เรียกครั้งสุดท้าย",
  "Landed": "ลงจอด"
};

export const FlightStatusColors = {
  "Confirmed": green,
  "Check-in Open": green,
  "Counter Closed": red,
  "Boarding": blue,
  "Delayed": yellow,
  "Cancelled": red,
  "Gate Open": green,
  "Gate Close": red,
  "Final Call": yellow,
  "Landed": green
};
