import React, { Component} from 'react';
import { authentication } from '../services/Authentication';

export default class Login extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: ''
        }
        this.change = this.change.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    change(e){
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(event){
        event.preventDefault();

        authentication.login(this.state.username, this.state.password)
        .then(data => {
            console.log(JSON.stringify(data))
                if(data.status_message === "Success"){
                    const { from } = this.props.location.state || { from: { pathname: "/home"}};
                    this.props.history.push(from);
                }else{
                    console.log(JSON.stringify(data));
                    alert("ERROR:"+data.status_message);
                }
                
            },
            error => {
                console.log(error);
            }
        )
    }
    
    render(){
        document.body.style.background = 'url("images/bg-login.png")';
      return (
        <div className="login">
            <div className="container">
                <img src="images/logo-login.png" className="logo" />
                <h3>Welcome to</h3>
                <label>U-tapao International Airport Wifi Service</label>
                <div className="box">
                <form action="/home" method="GET" onSubmit={this.handleSubmit}>
                    <input type="text" placeholder="Email" name="username" id="username" onChange={this.change}/>
                    <input type="password" placeholder="Password" name="password" id="password" onChange={this.change}/>
                    <input type="submit" className="btn full-width" value="Login"/>
                </form>
                </div>
                <div className="text-center mt-2">
                    <p>powered by</p>
                    <img src="icon/ais.png" width="120" height="auto"/>
                </div>
            </div>
        </div>
    );
    }
  };