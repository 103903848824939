import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "react-photo-view/dist/index.css";

const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);
const style = {
  width: "93%",
  height: "50%",
};
let map = {};
class TravelDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      detail: "",
      img: "",
      img_cover: "",
      sort: "",
      pin: "",
      floor: "",
      open: "",
      address: "",
      latitude: 0,
      longitude: 0,
      photos: [],
      telephone: "",
      email: "",
      id: "",
      center: {},
    };
    this.dataTable = this.dataTable.bind(this);
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable();
  }

  renderMap() {
    this.setState({
      HtmlMap: (
        <Map
          google={this.props.google}
          style={style}
          initialCenter={this.state.center}
          zoom={14}
        >
          <Marker onClick={this.onMarkerClick} name={"Current location"} />
          <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow>
        </Map>
      ),
    });
  }

  dataTable() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    fetch(
      config.url.API_URL +
        "/customerservice/" +
        this.props.id +
        "?is_type=tourist",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data)) {
          var datas = data.data[0];
          this.setState({
            id: datas.is_id,
            name: datas.is_name,
            detail: datas.is_detail,
            img: datas.is_img,
            img_cover: datas.is_img_cover,
            sort: datas.is_sort,
            pin: datas.is_pin,
            floor: datas.is_floor,
            open: datas.is_open,
            address: datas.is_address,
            latitude: datas.is_latitude,
            longitude: datas.is_longitude,
            photos: datas.is_photos,
            telephone: datas.is_telephone,
            email: datas.is_email,
            rating: datas.is_rating_overall,
            center: {
              lat: datas.is_latitude,
              lng: datas.is_longitude,
            },
          });
          this.renderMap();
        }
      });
  }

  async changeLang(val, id) {
    await this.setState({
      lang: val,
      lang_id: id,
    });
    this.dataTable();
  }

  ratingStar(val, index) {
    const rating = +val || 0;
    if (rating >= index) return ""; // full
    if (rating <= index - 1) return "-o"; // empty
    return "-half-o";
  }

  render() {
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          background={
            this.state.img
              ? 'url("' + this.state.img + '") no-repeat center center'
              : 'url("../uploads/banner.png") no-repeat center center'
          }
          method=""
        />
        <div className="main main-detail">
          <div className="container">
            <article>
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="mt-sub90 overflow-visible">
                    <div className="card-body">
                      <div className="logo">
                        <div className="logo-hotel">
                          <img src={this.state.img_cover} />
                        </div>
                      </div>
                      <div className="text-center mt-2 rate-travel">
                        <div className="star">
                          <span>{(+this.state.rating || 0).toFixed(1)}</span>
                          <i
                            className={
                              "fa text-gold fa-star" +
                              this.ratingStar(this.state.rating, 1)
                            }
                            aria-hidden="true"
                          />
                          <i
                            className={
                              "fa text-gold fa-star" +
                              this.ratingStar(this.state.rating, 2)
                            }
                            aria-hidden="true"
                          />
                          <i
                            className={
                              "fa text-gold fa-star" +
                              this.ratingStar(this.state.rating, 3)
                            }
                            aria-hidden="true"
                          />
                          <i
                            className={
                              "fa text-gold fa-star" +
                              this.ratingStar(this.state.rating, 4)
                            }
                            aria-hidden="true"
                          />
                          <i
                            className={
                              "fa text-gold fa-star" +
                              this.ratingStar(this.state.rating, 5)
                            }
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <h2 className="text-center mt-4">{this.state.name}</h2>
                      <p className="card-text mt-3 p-2 detail">
                        {this.state.detail}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            {this.state.photos.length > 0 && (
              <article>
                <h3 className="m-3">PHOTOS</h3>
                <div
                  className="scroll-flexbox mt-2 p-2"
                  style={{ height: "170px", width: "104%" }}
                >
                  <PhotoProvider>
                    {this.state.photos.map((anObjectMapped, index) => {
                      return (
                        <PhotoConsumer
                          key={index}
                          src={anObjectMapped.photo_img}
                          intro={anObjectMapped.photo_img}
                        >
                          <img
                            src={anObjectMapped.photo_img}
                            alt=""
                            className="img-albums"
                            height="150px"
                            width="auto"
                          />
                        </PhotoConsumer>
                      );
                    })}
                  </PhotoProvider>
                </div>
              </article>
            )}
            <article className="mtl-5" style={{ position: "relative" }}>
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <h3 className="m-3">LOCATION</h3>
                  <p className="mb-2 pl-3 pr-3">{this.state.address}</p>
                </div>
                <div style={{ position: "relative", height: 500 }}>
                  {this.state.HtmlMap}
                </div>
              </div>
            </article>
          </div>

          <footer />
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCCkV-vRd_FGagWhbR_1I6XlloJB3MSLQA",
})(TravelDetail);
