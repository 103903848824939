import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      address: "",
    };
    this.dataTable = this.dataTable.bind(this);
    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable();
  }

  dataTable() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Language: this.state.lang_id,
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
    };

    fetch(config.url.API_URL + "/information", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          address: data.data.address,
          email: data.data.email,
          phone: data.data.phone_number,
        });
      });
  }

  async changeLang(val, id) {
    await this.setState({
      lang: val,
      lang_id: id,
    });
    this.dataTable(this.state.type);
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          background={'url("uploads/banner.png") no-repeat center center'}
          title="CONTACT US"
          method=""
        />
        <div className="main main-detail">
          <div className="container">
            <article>
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="overflow-visible mb-3">
                    <div className="card-body">
                      <h3 className="text-left mt-4">
                        {(this.state.lang == "TH" && <>ข้อมูลทั่วไป</>) ||
                          (this.state.lang == "AR" && <>استفسارات عامة</>) ||
                          (this.state.lang == "RU" && <>ОБЩИЕ ВОПРОСЫ</>) ||
                          (this.state.lang == "ZH" && <>通用查询</>) || (
                            <>GENERAL ENQUIRIES</>
                          )}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <article>
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="card" style={{ minHeight: "30px" }}>
                    <div className="card-body p-0">
                      <div
                        className="d-inline-block text-center"
                        style={{ width: "20%" }}
                      >
                        <i
                          class="fa fa-envelope-o text-grayblack"
                          aria-hidden="true"
                          style={{ fontSize: "28px" }}
                        ></i>
                      </div>
                      <div
                        className="d-inline-block p-3"
                        style={{ width: "80%" }}
                      >
                        <h3 className="pt-2">
                          {(this.state.lang == "TH" && <>อีเมล</>) ||
                            (this.state.lang == "AR" && (
                              <>البريد الإلكتروني</>
                            )) ||
                            (this.state.lang == "RU" && (
                              <>Электронное письмо</>
                            )) ||
                            (this.state.lang == "ZH" && <>电子邮件</>) || (
                              <>Email</>
                            )}
                        </h3>
                        <p className="text-grayblack detail">
                          {this.state.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="card" style={{ minHeight: "30px" }}>
                    <div className="card-body p-0">
                      <div
                        className="d-inline-block text-center"
                        style={{ width: "20%" }}
                      >
                        <i
                          class="fa fa-phone text-grayblack"
                          aria-hidden="true"
                          style={{ fontSize: "28px" }}
                        ></i>
                      </div>
                      <div
                        className="d-inline-block p-3"
                        style={{ width: "80%" }}
                      >
                        <h3 className="pt-2">
                          {(this.state.lang == "TH" && <>หมายเลขโทรศัพท์</>) ||
                            (this.state.lang == "AR" && <>رقم الهاتف</>) ||
                            (this.state.lang == "RU" && (
                              <>Телефонный номер</>
                            )) ||
                            (this.state.lang == "ZH" && <>电话号码</>) || (
                              <>Phone Number</>
                            )}
                        </h3>
                        <p className="text-grayblack detail">
                          {this.state.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="card" style={{ minHeight: "30px" }}>
                    <div className="card-body p-0">
                      <div
                        className="text-center"
                        style={{
                          width: "20%",
                          float: "left",
                          marginTop: "35px",
                        }}
                      >
                        <i
                          class="fa fa-building text-grayblack"
                          aria-hidden="true"
                          style={{ fontSize: "28px" }}
                        ></i>
                      </div>
                      <div
                        className="p-3 pl-4"
                        style={{ width: "80%", float: "left" }}
                      >
                        <h3 className="pt-2">
                          {(this.state.lang == "TH" && <>ที่อยู่</>) ||
                            (this.state.lang == "AR" && <>العنوان</>) ||
                            (this.state.lang == "RU" && <>Адрес</>) ||
                            (this.state.lang == "ZH" && <>地址</>) || (
                              <>Address</>
                            )}
                        </h3>
                        <p className="text-grayblack detail">
                          {this.state.address}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
