import React, { Component} from 'react';
import BarCover from "../component/BarCover";

export default class Dinning extends Component{
    
    constructor(props){
        super(props);

    }
    
    render(){
        //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
      return (
        <div>
            <BarCover background = {'url("uploads/banner.png") no-repeat center center'} method=""/>
            <div className="main">
                <div className="container">
                <section>
                    <div className="row">
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex6.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                    <div className="thumbnail">
                                        <img src="uploads/dinning/ex1.jpg" className="card-img-top" alt="..." />
                                        <div className="star">
                                        <i className="fa fa-star text-gold" aria-hidden="true" />
                                        <i className="fa fa-star text-gold" aria-hidden="true" />
                                        <i className="fa fa-star text-gold" aria-hidden="true" />
                                        <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                        <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                        </div>
                                    </div>
                                </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex2.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex3.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex4.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex5.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex6.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex1.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-4">
                        <div className="card">
                            <a href="/dinning-detail">
                                <div className="thumbnail">
                                    <img src="uploads/dinning/ex2.jpg" className="card-img-top" alt="..." />
                                    <div className="star">
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    <i className="fa fa-star-o text-gold" aria-hidden="true" />
                                    </div>
                                </div>
                            </a>
                        <div className="card-body">
                            <h3><a href="/dinning-detail">Dinning name</a></h3>
                            <span><i className="fa fa-map-marker" aria-hidden="true" /> 1.2 km</span>
                            <p className="card-text">Some quick example text to build on the card Dinning name and make up the bulk of the card's content.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-left" aria-hidden="true" /></a></li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-right" aria-hidden="true" /></a></li>
                    </ul>
                </nav>
                </div>
                <footer />
            </div>
            </div>

    );
    }
  };