import React, { Component } from "react";
import { config } from "../Constants";
import { encode } from "base-64";
import BarCover from "./BarCover";

import { isMobile } from "react-device-detect";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

import moment from "moment";
import "moment/locale/th";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";

import {
  FlightStatusColors,
  FlightStatusLangs,
} from "../constants/FlightStatus";

const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class FlightInfoDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flight_id: "",
      flight_code: "",
      flight_number: "",
      flight_sense: "",
      flight_status: "",
      flight_type: "",
      flight_from_date: "",
      flight_from_time: "",
      flight_to_date: "",
      flight_to_time: "",
      flight_boarding: "",
      flight_terminal_name: "",
      flight_airlines_name: "",
      flight_airlines_short_name: "",
      flight_airlines_logo: "",
      flight_location_name: "",
      flight_location_short_name: "",
      flight_destination_name: "",
      flight_destination_short_name: "",
      flight_counter_number: "",
      flight_checkin_close: "",
      flight_boarding_gate: "",
      flight_gate_close: "",
      flight_seat_no: "",
      flight_seq: "",
      flight_from_nation: "",
      flight_to_nation: "",
      flight_from_exchange: "",
      flight_to_exchange: "",
      flight_from_exchange_value: 0,
      flight_to_exchange_value: 0,
      flight_from_logo: "",
      flight_to_logo: "",
      wt_forecast_day: [],
      wt_forecast_time: [],
      agreeIsOpen: false,
      lang: "EN",
      locale: "en",
    };

    this.dataTable = this.dataTable.bind(this);
    this.dataExchange = this.dataExchange.bind(this);
    this.getWeather = this.getWeather.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.closeAgree = this.closeAgree.bind(this);
    this.openAgree = this.openAgree.bind(this);
    this.submit = this.submit.bind(this);

    this.dataTable();
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    if (lang == "TH") {
      this.setState({
        locale: "th",
      });
    } else if (lang == "EN") {
      this.setState({
        locale: "en",
      });
    } else if (lang == "RU") {
      this.setState({
        locale: "ru",
      });
    } else if (lang == "ZH") {
      this.setState({
        locale: "zh-cn",
      });
    }
  }

  //getweather?lat=\(lat)&lon=\(long)&flight_to_date=\(flightToDate)
  getWeather(lat, long, flightToDate) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
    };

    fetch(
      config.url.API_URL +
        `/getweather?lat=${lat}&lon=${long}&flight_to_date=${flightToDate}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        var datas = data.data;

        if (!!datas) {
          this.setState({
            wt_img: datas.wt_current.wt_img,
            wt_temp: datas.wt_current.wt_temp,
            wt_real_feel: datas.wt_current.wt_real_feel,
            wt_wind: datas.wt_current.wt_wind,
            wt_name: datas.wt_current.wt_name,
            wt_air_quality: datas.wt_current.wt_air_quality,
            wt_forecast_day: datas.wt_forecast_day,
            wt_forecast_time: datas.wt_forecast_time,
            wt_city_name: datas.wt_current.wt_city_name,
          });
        }
      });
  }

  dataExchange(from, to) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
    };

    fetch(
      config.url.API_URL +
        `/exchangerate?currency_from=${from}&currency_to=${to}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length > 0) {
          var datas = data.data[0];

          //console.log("************************************************"+JSON.stringify(data))
          this.setState({
            flight_from_exchange_value: 1,
            flight_to_exchange_value: datas.exchange_rate_from,
          });
        }
      });
  }

  dataTable() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
      },
    };

    fetch(
      config.url.API_URL +
        "/getflightsdetail" +
        "?flight_id=" +
        this.props.id +
        "&lang_id=602a2e58494182001af1e22a",
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length > 0) {
          var datas = data.data[0];
          this.dataExchange(
            datas.flight_from_exchange,
            datas.flight_to_exchange
          );
          this.getWeather(
            "12.676431159247102",
            "100.99766147723884",
            datas.flight_to_date
          );

          //console.log("*****weather*******"+JSON.stringify(this.state.weather))
          this.setState({
            flight_id: datas.flight_id,
            flight_code: datas.flight_code,
            flight_number: datas.flight_number,
            flight_sense: datas.flight_sense,
            flight_status: datas.flight_status,
            flight_type: datas.flight_type,
            flight_from_date: datas.flight_from_date,
            flight_from_time: datas.flight_from_time,
            flight_to_date: datas.flight_to_date,
            flight_to_time: datas.flight_to_time,
            flight_boarding: datas.flight_boarding,
            flight_terminal_name: datas.flight_terminal_name,
            flight_airlines_name: datas.flight_airlines_name,
            flight_airlines_short_name: datas.flight_airlines_short_name,
            flight_airlines_logo: datas.flight_airlines_logo,
            flight_location_name: datas.flight_location_name,
            flight_location_short_name: datas.flight_location_short_name,
            flight_destination_name: datas.flight_destination_name,
            flight_destination_short_name: datas.flight_destination_short_name,
            flight_counter_number: datas.flight_counter_number,
            flight_checkin_close: datas.flight_checkin_close,
            flight_boarding_gate: datas.flight_boarding_gate,
            flight_gate_close: datas.flight_gate_close,
            flight_seat_no: datas.flight_seat_no,
            flight_seq: datas.flight_seq,
            flight_from_nation: datas.flight_from_nation,
            flight_to_nation: datas.flight_to_nation,
            flight_from_logo: datas.flight_from_logo,
            flight_to_logo: datas.flight_to_logo,
            flight_from_exchange: datas.flight_from_exchange,
            flight_to_exchange: datas.flight_to_exchange,
          });
        }
      });
  }

  changeLang(val, id) {
    this.setState({
      lang: val,
      lang_id: id,
    });
  }

  tabBangkok() {
    document.getElementById("ex-bangkok").style.display = "block";
    document.getElementById("ex-scb").style.display = "none";

    document.getElementById("btn-bangkok").style.opacity = "100%";
    document.getElementById("btn-scb").style.opacity = "50%";
  }

  tabSCB() {
    document.getElementById("ex-bangkok").style.display = "none";
    document.getElementById("ex-scb").style.display = "block";

    document.getElementById("btn-bangkok").style.opacity = "50%";
    document.getElementById("btn-scb").style.opacity = "100%";
  }

  openWeather() {
    if (isMobile) {
      if (document.getElementById("card-weather").style.height == "720px") {
        document.getElementById("card-weather").style.height = "150px";
      } else {
        document.getElementById("card-weather").style.height = "720px";
      }
    } else {
      if (document.getElementById("card-weather").style.height == "970px") {
        document.getElementById("card-weather").style.height = "210px";
      } else {
        document.getElementById("card-weather").style.height = "970px";
      }
    }
  }

  openAgree() {
    this.setState({
      agreeIsOpen: true,
    });
  }

  closeAgree() {
    this.setState({
      agreeIsOpen: false,
    });
  }

  submit() {
    //https://thaiboxingapp.verkoffice.com/demo_utapao/
    //this.props.history.push("https://thaiboxingapp.verkoffice.com/demo_utapao/");
    this.setState({
      agreeIsOpen: false,
    });
  }

  render() {
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          heightBar="80px"
          background={'url("../uploads/banner.png") no-repeat center center'}
          method="baggae-claim"
          title="FLIGHT DETAIL"
        />
        <div className="main main-detail">
          <div className="container">
            <div className="d-flex justify-content-between bd-highlight mt-3 mb-2">
              <div className="bd-highlight">
                <div
                  className="refresh"
                  onClick={() => {
                    window.parent.location = window.parent.location.href;
                  }}
                >
                  <img src="../icon/refresh.png" class="icon-refresh" />
                </div>
                <div className="text-gray label-date">
                  {" "}
                  Update {moment().format("DD/MM/YY, HH:mm")}
                </div>
              </div>
              <div className="bd-highlight"></div>
            </div>
            <article className="">
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-body pl-5 pr-5 pb-3 pt-3">
                      <div className="row ">
                        <div className="col-12 col-p0 text-center pt-1">
                          <img
                            src={this.state.flight_airlines_logo}
                            className="icon-airline"
                          />{" "}
                          <span className="flight-number">
                            {this.state.flight_number}
                          </span>
                        </div>
                        <div className="col-4 col-p0 text-left cart-flight-l">
                          <h3>{this.state.flight_location_short_name}</h3>
                          <p className="card-text text-gray takeoff-airport">
                            {this.state.flight_location_name}
                          </p>
                        </div>
                        <div className="col-4 col-p0 text-center cart-flight-takeoff">
                          <div className="line"></div>
                          <img
                            src="../icon/icon-plan.png"
                            width="40"
                            height="auto"
                          />
                        </div>
                        <div className="col-4 col-p0 text-right cart-flight-r">
                          <h3>{this.state.flight_destination_short_name}</h3>
                          <p className="card-text text-gray takeoff-airport">
                            {this.state.flight_destination_name}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-p0 cart-flight-bottom text-left">
                          <p className="mb-0 filght-status">
                            {this.state.flight_sense == "A"
                              ? (this.state.lang == "TH" && (
                                  <span>เวลาเข้า</span>
                                )) ||
                                (this.state.lang == "AR" && (
                                  <span>وقت الوصول</span>
                                )) ||
                                (this.state.lang == "RU" && (
                                  <span>Время входа</span>
                                )) ||
                                (this.state.lang == "ZH" && (
                                  <span>入场时间</span>
                                )) || <span>Depart time</span>
                              : (this.state.lang == "TH" && (
                                  <span>เวลาออกเดินทาง</span>
                                )) ||
                                (this.state.lang == "AR" && (
                                  <span>وقت المغادرة</span>
                                )) ||
                                (this.state.lang == "RU" && (
                                  <span>Время отправления</span>
                                )) ||
                                (this.state.lang == "ZH" && (
                                  <span>出發時間</span>
                                )) || <span>Depart time</span>}
                          </p>
                          <p className="font-highlight">
                            {this.state.flight_sense == "A"
                              ? moment(this.state.flight_to_date)
                                  .locale(this.state.locale)
                                  .format("DD MMM YYYY") +
                                " " +
                                this.state.flight_to_time
                              : moment(this.state.flight_from_date)
                                  .locale(this.state.locale)
                                  .format("DD MMM YYYY") +
                                " " +
                                this.state.flight_from_time}
                          </p>
                        </div>
                        <div className="col-4 col-p0 cart-flight-bottom">
                          <p className="font-highlight text-center">
                            <div>
                              {this.state.flight_sense == "D" && "Check-in"}
                            </div>
                            {this.state.flight_sense == "D" && (
                              <sapn className="btn-default">
                                {this.state.flight_counter_number
                                  ? this.state.flight_counter_number
                                  : "-"}
                              </sapn>
                            )}
                          </p>
                        </div>
                        <div className="col-4 col-p0 cart-flight-bottom text-right">
                          <p className="mb-0 filght-status">
                            {(this.state.lang == "TH" && <span>สถานะ</span>) ||
                              (this.state.lang == "AR" && (
                                <span>الحالة</span>
                              )) ||
                              (this.state.lang == "RU" && (
                                <span>Статус</span>
                              )) ||
                              (this.state.lang == "ZH" && (
                                <span>地位</span>
                              )) || <span>Status</span>}
                          </p>
                          <p className="font-highlight">
                            <sapn
                              className="btn-status flight-status"
                              style={{
                                background:
                                  FlightStatusColors[this.state.flight_status],
                                color: "#FFFFFF",
                              }}
                            >
                              {this.state.lang == "TH"
                                ? FlightStatusLangs[this.state.flight_status]
                                : this.state.flight_status.toUpperCase()}
                            </sapn>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*
                            <div className="row">
                                <div className="col-xs-12 col-md-12 col-lg-12">
                                    <div className="card">
                                    <div className="card-body pl-4 pr-4">
                                        <div className="row border-bottom-gray">
                                            <div className="col-4 text-center cart-flight-takeoff">
                                                <img src={"../icon/takeoff-black.png"} width="70" height="auto"/>
                                                <h3 className="mt-2">{this.state.flight_status}</h3>
                                            </div>
                                            <div className="col-3 text-center cart-flight-l">
                                                <h3>{this.state.flight_location_short_name}</h3>
                                                <p className="card-text text-gray takeoff-airport">{this.state.flight_location_name}</p>
                                                <p className="text-blue font14">{this.state.flight_from_time}</p>
                                            </div>
                                            <div className="col-2 text-center cart-flight-icon-plan" >
                                                <img src={"../icon/icon-plan.png"} width="30" height="auto" />
                                            </div>
                                            <div className="col-3 text-center cart-flight-r">
                                                <h3>{this.state.flight_destination_short_name}</h3>
                                                <p className="card-text text-gray takeoff-airport">{this.state.flight_destination_name}</p>
                                                <p className="text-blue font14">{this.state.flight_to_time}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>เที่ยวบินหมายเลข</span>)
                                        || this.state.lang == "EN" && (<span>Flight Number</span>)
                                        || this.state.lang == "RU" && (<span>Номер рейса</span>)
                                        || this.state.lang == "ZH" && (<span>航班號</span>)
                                        || (<span>Flight Number</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">
                                                    <img src={"../icon/logo-airasia.png"} className="icon-airline"/> {this.state.flight_number}</p>
                                            </div>
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>วันเดินทาง</span>)
                                        || this.state.lang == "EN" && (<span>Departure Date</span>)
                                        || this.state.lang == "RU" && (<span>Дата отбытия</span>)
                                        || this.state.lang == "ZH" && (<span>出發日期</span>)
                                        || (<span>Departure Date</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_from_time}</p>
                                            </div>
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>เวลาเดินทาง</span>)
                                        || this.state.lang == "EN" && (<span>Boarding Time</span>)
                                        || this.state.lang == "RU" && (<span>Время посадки</span>)
                                        || this.state.lang == "ZH" && (<span>登機時間</span>)
                                        || (<span>Boarding Time</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_boarding}</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article className="">
                            <div className="row">
                                <div className="col-xs-12 col-md-12 col-lg-12">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>หมายเลขเคาน์เตอร์เช็คอิน</span>)
                                        || this.state.lang == "EN" && (<span>Check-in Counter Number</span>)
                                        || this.state.lang == "RU" && (<span>Номер стойки регистрации</span>)
                                        || this.state.lang == "ZH" && (<span>登機櫃檯編號</span>)
                                        || (<span>Check-in Counter Number</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_counter_number}</p>
                                            </div>
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>เช็คอินปิด</span>)
                                        || this.state.lang == "EN" && (<span>Check-in Close</span>)
                                        || this.state.lang == "RU" && (<span>Заезд Закрыть</span>)
                                        || this.state.lang == "ZH" && (<span>登機手續關閉</span>)
                                        || (<span>Check-in Close</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_checkin_close}</p>
                                            </div>
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>ประตูขึ้นเครื่อง</span>)
                                        || this.state.lang == "EN" && (<span>Boarding Gate</span>)
                                        || this.state.lang == "RU" && (<span>Вход на посадку</span>)
                                        || this.state.lang == "ZH" && (<span>登機口</span>)
                                        || (<span>Boarding Gate</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_boarding_gate}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>ประตูลงเครื่อง</span>)
                                        || this.state.lang == "EN" && (<span>Gate Close</span>)
                                        || this.state.lang == "RU" && (<span>Ворота закрыть</span>)
                                        || this.state.lang == "ZH" && (<span>閘門關閉</span>)
                                        || (<span>Gate Close</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_gate_close}</p>
                                            </div>
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>หมายเลขที่นั่ง</span>)
                                        || this.state.lang == "EN" && (<span>Seat Number</span>)
                                        || this.state.lang == "RU" && (<span>Номер места</span>)
                                        || this.state.lang == "ZH" && (<span>座位號</span>)
                                        || (<span>Seat Number</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_seat_no}</p>
                                            </div>
                                            <div className="col-4 cart-flight-bottom">
                                                <p className="text-gray mb-0 text-center">{
                                        this.state.lang == "TH" && (<span>ลำดับหมายเลข</span>)
                                        || this.state.lang == "EN" && (<span>Sequence Number</span>)
                                        || this.state.lang == "RU" && (<span>Последовательность чисел</span>)
                                        || this.state.lang == "ZH" && (<span>序列號</span>)
                                        || (<span>Sequence Number</span>)
                                    }</p>
                                                <p className="text-blue font-highlight text-center">{this.state.flight_seq}</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                <article>
                */}
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12">
                  <h2 className="m-3">
                    {(this.state.lang == "TH" && <span>พยากรณ์อากาศ</span>) ||
                      (this.state.lang == "AR" && <span>توقعات الطقس</span>) ||
                      (this.state.lang == "RU" && (
                        <span>Прогноз погоды</span>
                      )) ||
                      (this.state.lang == "ZH" && <span>天氣預報</span>) || (
                        <span>WEATHER FORECAST</span>
                      )}
                  </h2>
                  <div
                    className="card-weather"
                    id="card-weather"
                    style={{ height: isMobile ? "150px" : "210px" }}
                  >
                    <div className="card-body p-4">
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-4 p-0 text-center">
                              <img
                                src={this.state.wt_img}
                                width="100"
                                height="auto"
                                className="prophecy"
                              />
                            </div>
                            <div className="col-8 p-0">
                              <span className="font-degree">
                                {this.state.wt_temp}°
                              </span>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "30px",
                                  color: "#333",
                                }}
                              >
                                c
                              </span>
                            </div>
                          </div>
                          <div className="font-nation text-blue">
                            <span>{this.state.wt_city_name}</span>,<br />
                            {this.state.flight_to_nation}
                          </div>
                        </div>
                        <div className="col-6">
                          <ul className="list-weather">
                            <li>
                              <span className="header">
                                {(this.state.lang == "TH" && (
                                  <span>รู้สึกเหมือน</span>
                                )) ||
                                  (this.state.lang == "AR" && (
                                    <span>أريد</span>
                                  )) ||
                                  (this.state.lang == "RU" && (
                                    <span>Как будто</span>
                                  )) ||
                                  (this.state.lang == "ZH" && (
                                    <span>感觉像</span>
                                  )) || <span>Feels Like</span>}
                              </span>
                              <span style={{ float: "right" }}>
                                <div className="air-quality">
                                  {this.state.wt_real_feel}°c
                                </div>
                              </span>
                            </li>
                            <li>
                              <span className="header">
                                {(this.state.lang == "TH" && (
                                  <span>คุณภาพอากาศ</span>
                                )) ||
                                  (this.state.lang == "AR" && (
                                    <span>جودة الهواء</span>
                                  )) ||
                                  (this.state.lang == "RU" && (
                                    <span>Качество воздуха</span>
                                  )) ||
                                  (this.state.lang == "ZH" && (
                                    <span>空氣質量</span>
                                  )) || <span>Air Quality</span>}
                              </span>
                              <span style={{ float: "right" }}>
                                <div className="text-orange air-quality">
                                  {this.state.wt_air_quality}
                                </div>
                              </span>
                            </li>
                            <li style={{ borderBottom: "none" }}>
                              <span className="header">
                                {(this.state.lang == "TH" && <span>ลม</span>) ||
                                  (this.state.lang == "AR" && (
                                    <span>رياح</span>
                                  )) ||
                                  (this.state.lang == "RU" && (
                                    <span>Ветер</span>
                                  )) ||
                                  (this.state.lang == "ZH" && (
                                    <span>風</span>
                                  )) || <span>Wind</span>}
                              </span>
                              <span style={{ float: "right" }}>
                                <div className="air-quality">
                                  {this.state.wt_wind}
                                </div>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="row mt-3 pt-3 pb-3 mb-3"
                        style={{
                          borderTop: "1px solid #CCC",
                          borderBottom: "1px solid #CCC",
                        }}
                      >
                        {this.state.wt_forecast_time.map((datas, i) => {
                          if (i % 3 === 0 && i < 17) {
                            return (
                              <div className="col-2 col-weatherfont">
                                <div className="text-center icon-weatherfont">
                                  {datas.wt_time}
                                </div>
                                <div className="text-center mb-3">
                                  <img
                                    src={datas.wt_img}
                                    className="icon-weathermap"
                                  />
                                </div>
                                <div className="icon-weatherfont text-center mb-3">
                                  {datas.wt_temp}°
                                </div>
                                <div className="text-center mb-3">
                                  <img
                                    src={
                                      window.location.origin + "/icon/wind.png"
                                    }
                                    height="auto"
                                    width="50%"
                                  />
                                </div>
                                <div className="text-center icon-weatherfont">
                                  {datas.wt_wind}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div className="row row-weatherfont">
                        <div className="col-4 text-left font-day pt-2"></div>
                        <div className="col-4 text-center"></div>
                        <div
                          className="col-4 font-deegree pt-2"
                          style={{ fontSize: 12 }}
                        >
                          Day/Night
                        </div>
                      </div>
                      {this.state.wt_forecast_day.map((datas) => {
                        return (
                          <div className="row row-weatherfont">
                            <div className="col-4 text-left font-day pt-2">
                              {datas.wt_day}
                            </div>
                            <div className="col-4 text-center">
                              <img
                                src={datas.wt_img}
                                className="icon-weathermap-bottom"
                              />
                            </div>
                            <div className="col-4 font-deegree pt-2">
                              {datas.wt_temp_max}° {datas.wt_temp_min}°
                            </div>
                          </div>
                        );
                      })}
                      <div className="row mt-3 weather-more">
                        <div className="col-4"></div>
                        <div className="col-4 text-center">
                          <div
                            onClick={this.openWeather}
                            id="weather-more"
                          ></div>
                        </div>
                        <div className="col-4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            {this.state.flight_type != "domestics" && (
              <article>
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-lg-12">
                    <h2 className="m-3">
                      {(this.state.lang == "TH" && (
                        <span>อัตราแลกเปลี่ยน</span>
                      )) ||
                        (this.state.lang == "AR" && <span>أسعار الصرف</span>) ||
                        (this.state.lang == "RU" && (
                          <span>Обменные курсы</span>
                        )) ||
                        (this.state.lang == "ZH" && <span>匯率</span>) || (
                          <span>EXCHANGE RATES</span>
                        )}
                    </h2>
                    <div className="bd-highlight mt-3 mb-2">
                      <div className="bd-highlight">
                        <div
                          className="bank-exchange ml-3"
                          onClick={this.tabBangkok}
                          id="btn-bangkok"
                        >
                          <img
                            src="../icon/bank-bangkok.png"
                            class="icon-bank"
                          />
                        </div>
                        <div
                          className="bank-exchange"
                          style={{ opacity: "50%" }}
                          onClick={this.tabSCB}
                          id="btn-scb"
                        >
                          <img src="../icon/bank-scb.png" class="icon-bank" />
                        </div>
                      </div>
                      <div className="bd-highlight"></div>
                    </div>

                    <div
                      className="card"
                      id="ex-bangkok"
                      style={{ minHeight: "35px" }}
                    >
                      <div className="card-body p-4">
                        <div className="row">
                          <div className="col-5 text-center">
                            <div>
                              <img
                                src={this.state.flight_from_logo}
                                className="cart-flight-icon-flag"
                              />
                            </div>
                            <p className="mt-3 mb-2 font-weight-bold">
                              {this.state.flight_from_exchange}
                            </p>
                            <p className="">
                              ${" "}
                              <span className="card-curent">
                                {this.state.flight_to_exchange_value}
                              </span>
                            </p>
                            {/* <p className="text-gray pt-0">{this.state.flight_from_exchange_value} {this.state.flight_from_exchange} = {this.state.flight_to_exchange_value} {this.state.flight_to_exchange}</p> */}
                          </div>
                          <div
                            className="col-2"
                            style={{ position: "relative" }}
                          >
                            <img
                              src={"../icon/logo-change.png"}
                              className="cart-flight-icon-change"
                            />
                          </div>
                          <div className="col-5 text-center">
                            <div>
                              <img
                                src={this.state.flight_to_logo}
                                className="cart-flight-icon-flag"
                              />
                            </div>
                            <p className="mt-3 mb-2 font-weight-bold">
                              {this.state.flight_to_exchange}
                            </p>
                            <p className="">
                              ${" "}
                              <span className="card-curent">
                                {this.state.flight_from_exchange_value}
                              </span>
                            </p>
                            {/* <p className="text-gray pt-0">{this.state.flight_to_exchange_value} {this.state.flight_to_exchange} = {this.state.flight_from_exchange_value} {this.state.flight_from_exchange}</p> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card"
                      id="ex-scb"
                      style={{ display: "none" }}
                    >
                      <div
                        className="card-body p-4"
                        style={{ minHeight: "35px" }}
                      >
                        <div className="row">
                          <div
                            className="col-12 text-center"
                            style={{ position: "relative" }}
                          >
                            <img src="../icon/bank-scb.png" class="icon-bank" />
                            <p className="h3 text-gray">
                              Please contact SCB Counter.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            )}
            <div className="row mt-5">
              <div className="col-12 text-center">
                <a
                  onClick={this.openAgree}
                  className="btn-blue form-control text-white cursor-point"
                  style={{ borderRadius: "20px" }}
                >
                  ADD TO MY FLIGHT
                </a>
              </div>
            </div>
          </div>
          <footer />
        </div>
        <Modal
          show={this.state.agreeIsOpen}
          onHide={this.closeAgree}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalBody>
            {(this.state.lang == "TH" && (
              <p>
                หากต้องการใช้ฟีเจอร์ Add to my flight โปรดดาวน์โหลด Thailand
                Smart Airport Application
              </p>
            )) || (
              <p>
                To use Add to my flight feature please download Thailand Smart
                Airport Application
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <a
              href="https://thaiboxingapp.verkoffice.com/demo_utapao/"
              className="btn btn-success text-white"
            >
              OK
            </a>
            <button className="btn btn-danger" onClick={this.closeAgree}>
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
