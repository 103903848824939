import React, { Component } from "react";
import { isMobile } from "react-device-detect";

export default class Menu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <nav className="main-menu">
        <ul className="menu row" style={{ width: isMobile ? "100%" : "100%" }}>
          <li
            className="menu-item"
            style={{
              maxWidth: "33.33333333333333%",
              flex: "0 0 33.33333333333333%",
            }}
          >
            <a href="/flight-info">
              <div className="item icon-flight-info">
                {(this.props.lang == "TH" && (
                  <p style={{ bottom: "0px", fontSize: "12px" }}>
                    ข้อมูลเที่ยวบิน
                  </p>
                )) ||
                  (this.props.lang == "RU" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>Мой полет</p>
                  )) ||
                  (this.props.lang == "ZH" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>我的航班</p>
                  )) ||
                  (this.props.lang == "AR" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>
                      معلومات الرحلة
                    </p>
                  )) || (
                    <p style={{ bottom: "-10px" }}>
                      <span>
                        FLIGHT
                        <br />
                        INFO
                      </span>
                    </p>
                  )}
              </div>
            </a>
          </li>
          <li
            className="menu-item"
            style={{
              maxWidth: "33.33333333333333%",
              flex: "0 0 33.33333333333333%",
            }}
          >
            <a href="/baggage-claim-search">
              <div className="item icon-baggage-claim">
                {(this.props.lang == "TH" && (
                  <p style={{ bottom: "0px", fontSize: "12px" }}>กระเป๋า</p>
                )) ||
                  (this.props.lang == "AR" && (
                    <p style={{ bottom: "-10px" }}>استلام الأمتعة</p>
                  )) ||
                  (this.props.lang == "RU" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>БАГАЖ ИСК</p>
                  )) ||
                  (this.props.lang == "ZH" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>行李认领</p>
                  )) || (
                    <p style={{ bottom: "-10px" }}>
                      <span>
                        BAGGAGE
                        <br />
                        CLAIM
                      </span>
                    </p>
                  )}
              </div>
            </a>
          </li>
          <li
            className="menu-item "
            style={{
              maxWidth: "33.33333333333333%",
              flex: "0 0 33.33333333333333%",
            }}
          >
            <a href="/shopping">
              <div className="item icon-shopping-dinning">
                {(this.props.lang == "TH" && (
                  <p style={{ bottom: "-10px" }}>
                    <span>
                      ช้อปปิ้ง & <br />
                      อาหารและเครื่องดื่ม
                    </span>
                  </p>
                )) ||
                  (this.props.lang == "AR" && (
                    <p style={{ bottom: "-10px" }}>
                      <span>التسوق وتناول الطعام</span>
                    </p>
                  )) ||
                  (this.props.lang == "RU" && (
                    <p style={{ bottom: "-10px" }}>
                      <span>
                        покупка товаров & <br />
                        обеденный
                      </span>
                    </p>
                  )) ||
                  (this.props.lang == "ZH" && (
                    <p style={{ bottom: "-10px" }}>
                      <span>
                        购物 & <br />
                        用餐
                      </span>
                    </p>
                  )) || (
                    <p style={{ bottom: "-10px" }}>
                      <span>
                        SHOPPING & <br />
                        DINING
                      </span>
                    </p>
                  )}
              </div>
            </a>
          </li>
        </ul>
        <ul className="menu row" style={{ width: isMobile ? "100%" : "100%" }}>
          <li
            className="menu-item "
            style={{
              maxWidth: "33.33333333333333%",
              flex: "0 0 33.33333333333333%",
            }}
          >
            <a href="/transportation">
              <div className="item icon-transportation">
                {(this.props.lang == "TH" && (
                  <p style={{ bottom: "0px", fontSize: "12px" }}>การขนส่ง</p>
                )) ||
                  (this.props.lang == "AR" && (
                    <p style={{ bottom: "0px" }}>المواصلات</p>
                  )) ||
                  (this.props.lang == "RU" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>Транспорт</p>
                  )) ||
                  (this.props.lang == "ZH" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>运输</p>
                  )) || <p style={{ bottom: "0px" }}>TRANSPORTATION</p>}
              </div>
            </a>
          </li>
          <li
            className="menu-item "
            style={{
              maxWidth: "33.33333333333333%",
              flex: "0 0 33.33333333333333%",
            }}
          >
            <a href="/travel">
              <div className="item icon-travel">
                {(this.props.lang == "TH" && (
                  <p style={{ bottom: "0px", fontSize: "12px" }}>
                    <span>แหล่งท่องเที่ยว</span>
                  </p>
                )) ||
                  (this.props.lang == "AR" && (
                    <p style={{ bottom: "-10px" }}>المعالم السياحية</p>
                  )) ||
                  (this.props.lang == "RU" && (
                    <p style={{ bottom: "-10px", fontSize: "9px" }}>
                      <span>
                        ТУРИСТИЧЕСКАЯ <br />
                        ПРИВЛЕКАТЕЛЬНОСТЬ
                      </span>
                    </p>
                  )) ||
                  (this.props.lang == "ZH" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>
                      <span>旅游景点</span>
                    </p>
                  )) || (
                    <p style={{ bottom: "-10px" }}>
                      <span>
                        TOURIST
                        <br />
                        ATTRACTION
                      </span>
                    </p>
                  )}
              </div>
            </a>
          </li>
          <li
            className="menu-item "
            style={{
              maxWidth: "33.33333333333333%",
              flex: "0 0 33.33333333333333%",
            }}
          >
            <a href="/airport-services">
              <div className="item icon-airport-services">
                {(this.props.lang == "TH" && (
                  <p style={{ bottom: "0px", fontSize: "12px" }}>สนามบิน</p>
                )) ||
                  (this.props.lang == "AR" && (
                    <p style={{ bottom: "-10px" }}>المطار والخدمة</p>
                  )) ||
                  (this.props.lang == "RU" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>аэропорт</p>
                  )) ||
                  (this.props.lang == "ZH" && (
                    <p style={{ bottom: "0px", fontSize: "12px" }}>飞机场</p>
                  )) || (
                    <p style={{ bottom: "-10px" }}>
                      <span>
                        AIRPORT
                        <br />
                        SERVICES
                      </span>
                    </p>
                  )}
              </div>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
