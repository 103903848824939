import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";
import moment from "moment";
import "moment/locale/th";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import "moment/locale/en-gb";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";

import {
  FlightStatusColors,
  FlightStatusLangs,
} from "../constants/FlightStatus";

const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class Shopping extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search;
    this.state = {
      languages: [],
      pages: [],
      search: new URLSearchParams(search).get("search"),
      //search:search,
      type: "flight",
      htmlnone: "",
      lang: "EN",
      locale: "en",
    };

    this.dataTable = this.dataTable.bind(this);
    this.dataDetail = this.dataDetail.bind(this);
    this.change = this.change.bind(this);
    if (this.state.search) {
      this.dataTable(this.state.search);
    }
    //this.dataTable();
    //flight-search
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });
    if (lang == "TH") {
      this.setState({
        locale: "th",
      });
    } else if (lang == "EN") {
      this.setState({
        locale: "en",
      });
    } else if (lang == "RU") {
      this.setState({
        locale: "ru",
      });
    } else if (lang == "ZH") {
      this.setState({
        locale: "zh-cn",
      });
    }
  }

  change(e) {
    if (e.target.value.length > 2) {
      //console.log(e.target.value);
      this.dataTable(e.target.value);
    } else {
      this.setState({ htmlnone: "", pages: [] });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  dataTable(search) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    fetch(
      config.url.API_URL +
        "/search" +
        "?type=flight&flight_sense=A&keyword=" +
        (search ? search : ""),
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              flight_id: datas.flight_id,
              flight_code: datas.flight_code,
              flight_number: datas.flight_number,
              flight_sense: datas.flight_sense,
              flight_status: datas.flight_status,
              flight_type: datas.flight_type,
              flight_from_date: datas.flight_from_date,
              flight_from_time: datas.flight_from_time,
              flight_to_date: datas.flight_to_date,
              flight_to_time: datas.flight_to_time,
              flight_boarding: datas.flight_boarding,
              flight_terminal_name: datas.flight_terminal_name,
              flight_airlines_name: datas.flight_airlines_name,
              flight_airlines_short_name: datas.flight_airlines_short_name,
              flight_airlines_logo: datas.flight_airlines_logo,
              flight_location_name: datas.flight_location_name,
              flight_location_short_name: datas.flight_location_short_name,
              flight_destination_name: datas.flight_destination_name,
              flight_destination_short_name:
                datas.flight_destination_short_name,
              flight_counter_number: datas.flight_counter_number,
              flight_checkin_close: datas.flight_checkin_close,
              flight_boarding_gate: datas.flight_boarding_gate,
              flight_gate_close: datas.flight_gate_close,
              flight_seat_no: datas.flight_seat_no,
              flight_seq: datas.flight_seq,
              flight_from_nation: datas.flight_from_nation,
              flight_to_nation: datas.flight_to_nation,
              flight_from_exchange: datas.flight_from_exchange,
              flight_to_exchange: datas.flight_to_exchange,
              flight_from_logo: datas.flight_from_logo,
              flight_to_logo: datas.flight_to_logo,
            };
          });
          this.setState({
            pages: [].concat(FromApi),
            srcIsOpen: false,
          });
        } else {
          this.setState({
            htmlnone: (
              <div className="text-center mt-5">
                <p className="text-black font20 font-white">ไม่พบข้อมูลค้นหา</p>
              </div>
            ),
            pages: [],
          });
        }
      });
  }

  dataDetail(e) {
    this.props.history.push(
      "/flight-info-detail/" + e.currentTarget.attributes["id"].value
    );
  }

  async handleSubmit(e) {
    this.dataTable(this.state.search);
    e.preventDefault();
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <div
          className="main"
          style={{
            height: this.state.pages.length > 0 ? "" : "100vh",
            marginTop: "0px",
            background:
              'url("../images/background-bag.png") no-repeat center center / cover',
            backgroundAttachment: "fixed",
          }}
        >
          <div className="container">
            <div className="container" style={{ clear: "both" }}>
              <div className="d-flex justify-content-between bd-highlight mb-6">
                <div className="p-0 bd-highlight pt-2">
                  <div className="text-center pt-1 font30">
                    <a href="/flight-info" className="text-white">
                      <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div className="p-2 bd-highlight">
                  <h2 class="text-center text-white pt-3">
                    {(this.state.lang == "TH" && "เที่ยวบิน") ||
                      (this.state.lang == "RU" && "Информация о рейсе") ||
                      (this.state.lang == "ZH" && "航班資訊") ||
                      (this.state.lang == "AR" && "الرحلات") ||
                      "FLIGHTS"}
                  </h2>
                </div>
                <div className="p-2 bd-highlight"></div>
              </div>
            </div>
            <section>
              <div
                className="input-group input-search mt-5"
                style={{ backgroundColor: "white" }}
              >
                <form onSubmit={this.handleSubmit} method="GET">
                  <input
                    autoFocus="autofocus"
                    type="text"
                    name="search"
                    id="flight-search"
                    className="form-control"
                    defaultValue={this.state.search}
                    placeholder=""
                    onChange={this.change}
                  />
                </form>
              </div>
              <div className="row mt-3">
                {this.state.pages.length > 0
                  ? this.state.pages.map((data, i) => {
                      return (
                        <article>
                          <div className="row">
                            <a
                              id={data.flight_id}
                              onClick={this.dataDetail}
                              className="flight-info cursor-point"
                            >
                              <div className="col-xs-12 col-md-12 col-lg-12 col-p0">
                                <div className="card">
                                  <div className="card-body pl-5 pr-5">
                                    <div className="row ">
                                      <div className="col-12 col-p0 text-center pt-1">
                                        <img
                                          src={data.flight_airlines_logo}
                                          className="icon-airline"
                                        />{" "}
                                        {data.flight_number}
                                      </div>
                                      <div className="col-4 col-p0 text-left cart-flight-l">
                                        <h3>
                                          {data.flight_location_short_name}
                                        </h3>
                                        <p className="card-text text-gray takeoff-airport">
                                          {data.flight_location_name}
                                        </p>
                                      </div>
                                      <div className="col-4 col-p0 text-center cart-flight-takeoff">
                                        <div className="line"></div>
                                        <img
                                          src="icon/icon-plan.png"
                                          width="40"
                                          height="auto"
                                        />
                                      </div>
                                      <div className="col-4 col-p0 text-right cart-flight-r">
                                        <h3>
                                          {data.flight_destination_short_name}
                                        </h3>
                                        <p className="card-text text-gray takeoff-airport">
                                          {data.flight_destination_name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-4 col-p0 cart-flight-bottom text-left">
                                        <p className="mb-0">
                                          {data.flight_sense == "A"
                                            ? (this.state.lang == "TH" && (
                                                <span>เวลาเข้า</span>
                                              )) ||
                                              (this.state.lang == "AR" && (
                                                <span>وقت الوصول</span>
                                              )) ||
                                              (this.state.lang == "RU" && (
                                                <span>Время входа</span>
                                              )) ||
                                              (this.state.lang == "ZH" && (
                                                <span>入场时间</span>
                                              )) || <span>Arrival time</span>
                                            : (this.state.lang == "TH" && (
                                                <span>เวลาออกเดินทาง</span>
                                              )) ||
                                              (this.state.lang == "AR" && (
                                                <span>وقت المغادرة</span>
                                              )) ||
                                              (this.state.lang == "RU" && (
                                                <span>Время отправления</span>
                                              )) ||
                                              (this.state.lang == "ZH" && (
                                                <span>出發時間</span>
                                              )) || <span>Depart time</span>}
                                        </p>
                                        <p className="font-highlight">
                                          {data.flight_sense == "A"
                                            ? moment(data.flight_to_date)
                                                .locale(this.state.locale)
                                                .format("DD MMM YYYY") +
                                              " " +
                                              data.flight_to_time
                                            : moment(data.flight_from_date)
                                                .locale(this.state.locale)
                                                .format("DD MMM YYYY") +
                                              " " +
                                              data.flight_from_time}
                                        </p>
                                      </div>
                                      <div className="col-4 col-p0 cart-flight-bottom">
                                        <p className="mb-0 text-center">
                                          {data.flight_sense == "D" &&
                                            "Check-in"}
                                        </p>
                                        <p className="font-highlight text-center">
                                          {data.flight_sense == "D" && (
                                            <sapn className="btn-default">
                                              {data.flight_counter_number
                                                ? data.flight_counter_number
                                                : "-"}
                                            </sapn>
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-4 col-p0 cart-flight-bottom text-right">
                                        <p className="mb-0">Status</p>
                                        {/* <p className="font-highlight">
                                                                    {(
                                                                        data.flight_status.toLowerCase() == "landed" && <sapn className="btn-status flight-status" style={{ background:"#29ABE2", color:"#FFFFFF" }}>{data.flight_status.toUpperCase()}</sapn>
                                                                    ||
                                                                        data.flight_status.toLowerCase() == "departed" && <sapn className="btn-status flight-status" style={{ background:"#8DC63F", color:"#FFFFFF" }}>{data.flight_status.toUpperCase()}</sapn>
                                                                    ||
                                                                        data.flight_status.toLowerCase() == "cancelled" && <sapn className="btn-status flight-status" style={{ background:"#FF0000", color:"#FFFFFF" }}>{data.flight_status.toUpperCase()}</sapn>
                                                                    )}
                                                                </p> */}
                                        <p className="font-highlight">
                                          <sapn
                                            className="btn-status flight-status"
                                            style={{
                                              background:
                                                FlightStatusColors[
                                                  data.flight_status
                                                ],
                                              color: "#FFFFFF",
                                            }}
                                          >
                                            {this.state.lang == "TH"
                                              ? FlightStatusLangs[
                                                  data.flight_status
                                                ]
                                              : data.flight_status.toUpperCase()}
                                          </sapn>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </article>
                      );
                    })
                  : this.state.htmlnone}
              </div>
            </section>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
