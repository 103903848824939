import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { config } from "../Constants";
import { encode } from "base-64";
import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
import MenuSlide from "../component/MenuSlide";

const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class BarCover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      pagesLang: [],
      langIsOpen: false,
    };

    this.openNav = this.openNav.bind(this);
    this.openLang = this.openLang.bind(this);

    this.openLang = this.openLang.bind(this);
    this.closeLang = this.closeLang.bind(this);
    this.submitLang = this.submitLang.bind(this);
    this.dataLang();
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });
    this.dataLang();
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "280px";
  }

  openLang() {
    this.setState({
      langIsOpen: true,
    });
  }

  closeLang() {
    this.setState({
      langIsOpen: false,
    });
  }

  submitLang(e) {
    //console.log(JSON.stringify(this.props))
    this.props.onChageLangSubmit(
      e.currentTarget.attributes["data-short"].value,
      e.currentTarget.attributes["data-id"].value
    );
    var data = {
      lang: e.currentTarget.attributes["data-short"].value,
      lang_id: e.currentTarget.attributes["data-id"].value,
    };
    localStorage.setItem("language", JSON.stringify(data));

    this.setState({
      langIsOpen: false,
      lang: data["lang"],
    });
    //this.dataLang();
    window.location.reload();
  }

  dataLang() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };

    fetch(config.url.API_URL + "/language", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var FromApi = data.data.map((datas) => {
            return {
              id: datas.lang_id,
              logo: datas.lang_logo,
              short_name: datas.lang_short_name,
              name: datas.lang_name,
              default: datas.lang_default,
            };
          });
          this.setState({
            pagesLang: [].concat(FromApi),
            srcIsOpen: false,
          });
          //console.log(JSON.stringify(this.state.pagesLang));
        } else {
          this.setState({
            pagesLang: [],
          });
        }
      });
  }

  render() {
    return (
      <div>
        <MenuSlide langDatas={this.state.pagesLang} />
        <div
          className="d-flex justify-content-between bd-highlight mb-0 pb-0"
          style={{ background: "#ffffff" }}
        >
          <div className="p-2 pl-3 bd-highlight pb-2 cursor-point">
            <img
              src={window.location.origin + "/icon/menu@2x.png"}
              className="icon-hamberger "
              onClick={this.openNav}
            />
          </div>
          <div className="p-2 pr-3bd-highlight">
            <div className="input-group input-search">
              <form method="GET" action="/search">
                {(this.props.method == "flight-info" && (
                  <input
                    type="text"
                    name="s"
                    className="form-control"
                    placeholder="Search flight"
                    onClick={() => {
                      window.location.href = "/flight-search";
                    }}
                  />
                )) || (
                  <input
                    type="text"
                    name="s"
                    className="form-control"
                    placeholder="Search flight,shop,service"
                  />
                )}
                <input
                  type="hidden"
                  name="src_type"
                  value={this.props.src_type}
                />
              </form>
            </div>
          </div>
          <div className="p-2 bd-highlight">
            <div className="lang mt-2">
              <a href="#" onClick={this.openLang} className="text-gray">
                {this.state.lang}
              </a>
            </div>
          </div>
        </div>
        <div
          className={"header-cover " + this.props.method}
          style={{
            background: this.props.background,
            height:
              (this.props.method == "baggage-claim" && "195px") ||
              (this.props.method == "shop" && "210px") ||
              (this.props.method == "travel" && "210px") ||
              (this.props.method == "transport" && "210px") ||
              (this.props.method == "service" && "210px") ||
              // || this.props.method == "about" &&  "250px"
              "auto",
            zIndex: "-2",
            position: this.props.method == "about" ? "relative" : "",
          }}
        >
          <div className="bg-gradient-cover-ut">
            <div className="background-about zone-center">
              <h2 className="text-center text-white pt-3">
                {this.props.title}
              </h2>
              {/* <div className="profile-about"><img src={"../images/about-us.png"} /></div> */}
            </div>
            {(this.props.method == "event" && this.props.showBar) ||
              (this.props.method == "flight-info" && this.props.showBar) ||
              (this.props.method == "baggage-claim" && (
                <div className="container" style={{ clear: "both" }}>
                  <div className="d-flex justify-content-between bd-highlight mb-6">
                    <div className="p-2 bd-highlight"></div>
                    <div className="p-2 bd-highlight"></div>
                  </div>
                </div>
              )) ||
              (this.props.method == "" && (
                <div className="container" style={{ clear: "both" }}>
                  <div className="d-flex justify-content-between bd-highlight mb-6">
                    <div className="p-2 bd-highlight"></div>
                    <div className="p-2 bd-highlight"></div>
                  </div>
                </div>
              ))}
          </div>
          <div id="mySidenav" className="sidenav">
            <a className="closebtn" onClick={this.closeNav}>
              &times;
            </a>
            <a href="#">
              {(this.state.lang == "TH" && <span>เกี่ยวกับเรา</span>) ||
                (this.state.lang == "AR" && <span>حول</span>) ||
                (this.state.lang == "RU" && <span>около</span>) ||
                (this.state.lang == "ZH" && <span>關於</span>) || (
                  <span>About</span>
                )}
            </a>
            <a href="#">
              {(this.state.lang == "TH" && <span>บริการ</span>) ||
                (this.state.lang == "AR" && <span>خدمات</span>) ||
                (this.state.lang == "RU" && <span>服務</span>) ||
                (this.state.lang == "ZH" && <span>Услуги</span>) || (
                  <span>Services</span>
                )}
            </a>
            <a href="#">
              {(this.state.lang == "TH" && <span>ลูกค้า</span>) ||
                (this.state.lang == "AR" && <span>العملاء</span>) ||
                (this.state.lang == "RU" && <span>Клиенты</span>) ||
                (this.state.lang == "ZH" && <span>客戶群</span>) || (
                  <span>Clients</span>
                )}
            </a>
            <a href="#">
              {(this.state.lang == "TH" && <span>ติดต่อเรา</span>) ||
                (this.state.lang == "AR" && <span>الاتصال</span>) ||
                (this.state.lang == "RU" && <span>Контакт</span>) ||
                (this.state.lang == "ZH" && <span>接觸</span>) || (
                  <span>Contact</span>
                )}
            </a>
          </div>
          {this.props.method == "about" && (
            <img
              className="img-about01"
              src={"../images/about-us02.jpg"}
              style={{ marginTop: "-20px" }}
            />
          )}
        </div>
        <Modal show={this.state.langIsOpen} onHide={this.closeLang}>
          <ModalHeader>
            <ModalTitle>Select language</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <form>
              {this.state.pagesLang.map((data) => {
                return (
                  <div className="radio">
                    <label>
                      <h5>
                        <a
                          data-short={data.short_name}
                          onClick={this.submitLang}
                          data-id={data.id}
                        >
                          {data.name}
                        </a>
                      </h5>
                    </label>
                  </div>
                );
              })}
            </form>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-danger" onClick={this.closeLang}>
              ยกเลิก
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
