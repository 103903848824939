import React, { Component } from "react";
import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);
export default class MenuSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      land_page: this.props.langDatas,
    };
    //console.log("langDatas:"+JSON.stringify(this.props.langDatas));
    this.closeNav = this.closeNav.bind(this);
  }
  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  submitLang(e) {
    //console.log(e.target.value+"--------"+e.target.selectedOptions[0].text)
    //console.log(JSON.stringify(this.props))

    //this.props.onChageLangSubmit(e.target.selectedOptions[0].text, e.target.value)
    var data = {
      lang: e.target.selectedOptions[0].text,
      lang_id: e.target.value,
    };

    localStorage.setItem("language", JSON.stringify(data));
    window.location.reload();
  }

  render() {
    return (
      <div id="mySidenav" className="sidenav">
        <a className="closebtn cursor-point" onClick={this.closeNav}>
          &times;
        </a>
        <div>
          Language :
          <select
            id="menu-lang"
            onChange={this.submitLang}
            className="cursor-point"
          >
            {this.props.langDatas.map((datas) => {
              return (
                <option
                  value={datas.id}
                  selected={this.state.lang_id == datas.id}
                >
                  {datas.short_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="line"></div>
        <a href="/home">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> หน้าหลัก
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> HOME
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> Дом
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 家
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> HOME
              </div>
            )}
        </a>
        <a href="/flight-info">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> เที่ยวบิน
            </div>
          )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> Информация о
                рейсе
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 航班資訊
              </div>
            )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> الرحلات
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> FLIGHTS
              </div>
            )}
        </a>
        <a href="/shopping">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i>{" "}
              ช้อปปิ้งและเครื่องดื่ม
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> التسوق وتناول
                الطعام
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> Покупки и
                рестораны
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 購物和餐飲
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> SHOPPING &
                DINING
              </div>
            )}
        </a>
        <a href="/baggage-claim-search">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> สัมภาระ
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> استلام الأمتعة
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> Получение багажа
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 行李領取
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> BAGGAGE CLAIM
              </div>
            )}
        </a>
        <a href="/travel">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> แหล่งท่องเที่ยว
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> المعالم السياحية
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> ТУРИСТИЧЕСКАЯ
                ДОСТОПРИМЕЧАТЕЛЬНОСТЬ
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 旅遊景點
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> TOURIST
                ATTRACTION
              </div>
            )}
        </a>
        <a href="/transportation">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> ขนส่งสถานะ
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> المواصلات
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> ТРАНСПОРТ
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 運輸
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> TRANSPORTATION
              </div>
            )}
        </a>
        <a href="/airport-services">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> บริการสนามบิน
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> المطار والخدمة
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> СЛУЖБА АЭРОПОРТА
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 機場服務
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> AIRPORT SERVICE
              </div>
            )}
        </a>
        <a href="https://www.ais.th/roaming/" target="_blank">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> แพ็คเกจโรมมิ่ง
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> تجوال الحزمة
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> ПАКЕТ РОУМИНГ
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 套餐漫遊
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> PACKAGE ROAMING
              </div>
            )}
        </a>
        <a
          href="https://www.ais.th/roaming/sim2fly/?intcid=getpage-th-header_menu-consumer_menu-prepaid_submenu1-newsim_package_submenu2-sim2fly_submenu3"
          target="_blank"
        >
          <div className="slide-menu">
            <i class="fa fa-circle" aria-hidden="true"></i> SIM2FLY
          </div>
        </a>
        <div className="line"></div>
        <a href="/aboutus">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> เกี่ยวกับเรา
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> نبذة عنا
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> О НАС
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 關於我們
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> ABOUT US
              </div>
            )}
        </a>
        <a href="/contactus">
          {(this.state.lang == "TH" && (
            <div className="slide-menu">
              <i class="fa fa-circle" aria-hidden="true"></i> ติดต่อเรา
            </div>
          )) ||
            (this.state.lang == "AR" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> اتصل بنا
              </div>
            )) ||
            (this.state.lang == "RU" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> СВЯЗАТЬСЯ С НАМИ
              </div>
            )) ||
            (this.state.lang == "ZH" && (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> 聯繫我們
              </div>
            )) || (
              <div className="slide-menu">
                <i class="fa fa-circle" aria-hidden="true"></i> CONTACT US
              </div>
            )}
        </a>
      </div>
    );
  }
}
