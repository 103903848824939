import { config } from '../Constants';
import { encode } from 'base-64';

export const authentication = {
    login,
    getLanguage
};

function getLanguage() {
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encode(config.url.USER + ':' + config.url.PASS)
        },
    };

    return fetch(config.url.API_URL + '/language'
        , requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("****++++****"+JSON.stringify(data))
            if (Array.isArray(data.data) && data.data.length) {
                var datas = data.data[0]
                var data_result = {
                    lang: datas.lang_short_name,
                    lang_id: datas.lang_id
                }
                localStorage.setItem('language', JSON.stringify(data_result));
                //console.log("****++++****"+JSON.stringify(data_result))
            }
            return data_result;
        });
}

function login(username, password) {
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + encode(config.url.USER + ':' + config.url.PASS)
        },
    };

    return fetch(config.url.API_URL + '/language'
        , requestOptions)
        .then(res => res.json())
        .then(data => {
            if (Array.isArray(data.data) && data.data.length) {
                var datas = data.data[0]
                var data_result = {
                    lang: datas.lang_short_name,
                    lang_id: datas.lang_id
                }
                localStorage.setItem('language', JSON.stringify(data_result));
            }
            return data;
        });
}
