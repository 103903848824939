import React, { Component} from 'react';
import { config } from '../Constants';
import { encode } from 'base-64';

export default class ShoppingDetail extends Component{
    
    constructor(props){
        super(props);

        this.state = {
            name: '',
            renderHtml: [],
        }
        
    }

    render(){
      return (
        <div>
            <iframe>{this.state.renderHtml}</iframe>
        </div>
    );
    }
  };