import React, { Component } from "react";
import BarCover from "./BarCover";
import { config } from "../Constants";
import { encode } from "base-64";
import moment from "moment";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);

export default class BaggageClaim extends Component {
  constructor(props) {
    super(props);
    const { lang, lang_id } = currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage();
    this.state = {
      lang: lang,
      lang_id: lang_id,
      flight_number: this.props.id,
      claim_number: "",
      pages: [],
    };

    this.dataTable = this.dataTable.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.change = this.change.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });

    this.dataTable();
  }

  dataTable() {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + encode(config.url.USER + ":" + config.url.PASS),
        Language: this.state.lang_id,
      },
    };
    //fetch(config.url.API_URL + '/baggage_claim'+ '?flight_number=' + this.props.id + '&lang_id=602a2e58494182001af1e22a'
    fetch(
      config.url.API_URL +
        "/getflightinfo?flight_type=&flight_sense=A&keyword=" +
        (this.state.flight_number ? this.state.flight_number : this.props.id) +
        "&flight_date=" +
        moment().format("YYYY-MM-DD"),
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.data) && data.data.length) {
          var datas = data.data;
          /*
                    active_status: 1
                    claim_number: "98"
                    create_by: ""
                    create_date: "2021-02-19T01:36:00.910Z"
                    delete_status: 0
                    description: ""
                    flight_number: "AK840"
                    update_by: ""
                    update_date: "2021-02-19T01:36:00.910Z"
                    __v: 0
                    _id: "604887ebb051dedf51ea0744"
                    */
          var FromApi = datas.map((datas) => {
            return {
              id: datas._id,
              claim_number: datas.flight_counter_number,
              flight_number: datas.flight_number,
              flight_airlines_logo: datas.flight_airlines_logo,
            };
          });

          this.setState({
            pages: [].concat(FromApi),
          });
        } else {
          this.setState({
            pages: [],
          });
        }
      });
  }

  changeLang(val, id) {
    this.setState({
      lang: val,
      lang_id: id,
    });
  }

  change(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <div>
        <BarCover
          heightBar="0px"
          onChageLangSubmit={this.changeLang}
          background={'url("../uploads/banner.png") no-repeat center center'}
          method="baggage-claim"
        />
        <div
          className="main"
          style={{
            height: "auto",
            minHeight: "100vh",
            marginTop: "-175px",
            background:
              'url("../images/background-bag.png") no-repeat center center / cover',
            backgroundAttachment: "fixed",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-12 col-lg-12">
                <h2 className="text-center text-white mt-4">
                  {(this.state.lang == "TH" && "กระเป๋า") ||
                    (this.state.lang == "AR" && "استلام الأمتعة") ||
                    (this.state.lang == "RU" && "ПОЛУЧЕНИЕ БАГАЖА") ||
                    (this.state.lang == "ZH" && "领取行李") ||
                    "BAGGAGE CLIAM"}
                </h2>
                <label
                  for="validationCustomUsername"
                  className="pb-0 mb-1 mt-4 text-white"
                >
                  {(this.state.lang == "TH" && <span>เที่ยวบินหมายเลข</span>) ||
                    (this.state.lang == "AR" && <span>رقم الرحلة</span>) ||
                    (this.state.lang == "RU" && <span>Номер рейса</span>) ||
                    (this.state.lang == "ZH" && <span>航班號</span>) || (
                      <span>Flight Number</span>
                    )}
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="flight_number"
                    onKeyPress={(event) => {
                      event.key === "Enter" && this.dataTable();
                    }}
                    onChange={this.change}
                    className="form-control"
                    id="validationCustomUsername"
                    placeholder=""
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      onClick={this.handleDetail}
                      id="inputGroupPrepend"
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
                {this.state.pages.map((data, i) => {
                  return (
                    <div className="card mt-4" style={{ minHeight: "30px" }}>
                      <div className="card-body text-center">
                        <div className="row">
                          <div className="col-3 text-center pl-3 pt-3 pr-0">
                            <img
                              src={data.flight_airlines_logo}
                              width="80%"
                              height="auto"
                            />
                          </div>
                          <div className="col-9 text-left">
                            <p className="mt-3 mb-0">
                              Flight Number : {data.flight_number}
                            </p>
                            <p className="text-black mt-0 text-gray">
                              Your baggage is on conveyor{" "}
                              <span
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                }}
                              >
                                {data.claim_number}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 mt-4">
                <label for="validationCustomUsername" className="pb-0">
                  {(this.state.lang == "TH" && (
                    <span className="text-white">
                      ไม่พบสัมภาระของคุณใช่หรือไม่ ?
                    </span>
                  )) ||
                    (this.state.lang == "AR" && (
                      <span className="text-white">
                        هل لم يتم العثور على أمتعتك؟
                      </span>
                    )) ||
                    (this.state.lang == "RU" && (
                      <span className="text-white">Не нашел свой багаж ?</span>
                    )) ||
                    (this.state.lang == "ZH" && (
                      <span className="text-white">找不到您的行李 ?</span>
                    )) || (
                      <span className="text-white">
                        Not found your baggage ?
                      </span>
                    )}
                </label>
                <div className="card" style={{ minHeight: "30px" }}>
                  <div className="card-body text-center">
                    <a href="/baggage-claim-detail">
                      <div className="row">
                        <div className="col-3 d-flex align-items-center">
                          <img
                            src={"../icon/logo-baggage-lost.png"}
                            className="logo-baggage"
                          />
                        </div>
                        <div className="col-7 col-sm-8 d-flex align-items-center justify-content-start">
                          <p className="text-black mt-3 font16">
                            {(this.state.lang == "TH" && (
                              <span>บริการทรัพย์สินสูญหาย</span>
                            )) ||
                              (this.state.lang == "AR" && (
                                <span>المفقودات والموجودات</span>
                              )) ||
                              (this.state.lang == "RU" && (
                                <span>ПОТЕРЯННЫЙ И НАЙДЕННЫЙ</span>
                              )) ||
                              (this.state.lang == "ZH" && (
                                <span>失物招領</span>
                              )) || <span>LOST & FOUND</span>}
                          </p>
                        </div>
                        <div className="col-1 d-flex align-items-center justify-content-end pdr15px">
                          <img
                            src={"../icon/logo-next-black.png"}
                            className="ic-ar-next"
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
