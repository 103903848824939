import React, { Component } from "react";
import BarCover from "./BarCover";

import "bootstrap/dist/css/bootstrap.min.css";

import { authentication } from "../services/Authentication";
import { BehaviorSubject } from "rxjs";
const currentLanguage = new BehaviorSubject(
  JSON.parse(localStorage.getItem("language"))
);
export default class BaggageClaimMenu extends Component {
  constructor(props) {
    super(props);
    const { lang, lang_id } = currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage();

    this.state = {
      lang: lang,
      lang_id: lang_id,
    };

    this.changeLang = this.changeLang.bind(this);
  }

  async componentWillMount() {
    const { lang, lang_id } = await (currentLanguage.value
      ? currentLanguage.value
      : authentication.getLanguage());

    this.setState({
      lang: lang,
      lang_id: lang_id,
    });
  }

  changeLang(val, id) {
    this.setState({
      lang: val,
      lang_id: id,
    });
  }

  render() {
    //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
    return (
      <div>
        <BarCover
          onChageLangSubmit={this.changeLang}
          heightBar="0px"
          background={'url("../uploads/banner.png") no-repeat center center'}
          method="baggage-claim"
        />
        <div
          className="main"
          style={{
            marginTop: "-195px",
            background:
              'url("../images/background-bag.png") no-repeat center center / cover',
            backgroundAttachment: "fixed",
          }}
        >
          <div className="container">
            <section>
              <h2 className="text-center text-white mt-4">
                {(this.state.lang == "TH" && "กระเป๋า") ||
                  (this.state.lang == "AR" && "استلام الأمتعة") ||
                  (this.state.lang == "RU" && "ПОЛУЧЕНИЕ БАГАЖА") ||
                  (this.state.lang == "ZH" && "领取行李") ||
                  "BAGGAGE CLIAM"}
              </h2>
              <div className="card mt-3" style={{ minHeight: "30px" }}>
                <div className="card-body text-center">
                  <a href="/baggage-claim-info">
                    <div className="row">
                      <div className="col-3 d-flex align-items-center">
                        <img
                          src={"../icon/logo-baggage-lost.png"}
                          className="logo-baggage"
                        />
                      </div>
                      <div className="col-7 col-sm-8 d-flex align-items-center justify-content-start">
                        <p className="text-black mt-0 font16">
                          {(this.state.lang == "TH" && (
                            <span className="bag-font">กระเป๋า</span>
                          )) ||
                            (this.state.lang == "AR" && (
                              <span className="bag-font">استلام الأمتعة</span>
                            )) ||
                            (this.state.lang == "RU" && (
                              <span className="bag-font">
                                ПОТЕРЯННЫЙ И НАЙДЕННЫЙ
                              </span>
                            )) ||
                            (this.state.lang == "ZH" && (
                              <span className="bag-font">失物招領</span>
                            )) || (
                              <span className="bag-font">BAGGAGE CLAIM</span>
                            )}
                        </p>
                      </div>
                      <div className="col-1 d-flex align-items-center justify-content-end pdr15px">
                        <img
                          src={"../icon/logo-next-black.png"}
                          className="ic-ar-next"
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="card mt-3" style={{ minHeight: "30px" }}>
                <div className="card-body text-center">
                  <a href="/baggage-claim-detail">
                    <div className="row">
                      <div className="col-3 d-flex align-items-center">
                        <img
                          src={"../icon/logo-baggage-info.png"}
                          className="logo-baggage"
                        />
                      </div>
                      <div className="col-7 col-sm-8 d-flex align-items-center justify-content-start">
                        <p className="text-black mt-0 font16">
                          {(this.state.lang == "TH" && (
                            <span className="bag-font">
                              บริการทรัพย์สินสูญหาย
                            </span>
                          )) ||
                            (this.state.lang == "AR" && (
                              <span className="bag-font">
                                المفقودات والموجودات
                              </span>
                            )) ||
                            (this.state.lang == "RU" && (
                              <span className="bag-font">
                                ПОТЕРЯННЫЙ И НАЙДЕННЫЙ
                              </span>
                            )) ||
                            (this.state.lang == "ZH" && (
                              <span className="bag-font">失物招領</span>
                            )) || (
                              <span className="bag-font">LOST & FOUND</span>
                            )}
                        </p>
                      </div>
                      <div className="col-1 d-flex align-items-center justify-content-end pdr15px">
                        <img
                          src={"../icon/logo-next-black.png"}
                          className="ic-ar-next"
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
          </div>
          <footer />
        </div>
      </div>
    );
  }
}
