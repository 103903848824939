import React, { Component} from 'react';
import BarCover from "./BarCover";
import { config } from '../Constants';
import { encode } from 'base-64';

import { authentication } from '../services/Authentication';
import { BehaviorSubject } from 'rxjs';
const currentLanguage = new BehaviorSubject(JSON.parse(localStorage.getItem('language')));

export default class AboutUs extends Component{

    constructor(props){
        super(props);
        this.state = {
            add_about_us: '',
            about_title: '',
        }

        this.dataTable = this.dataTable.bind(this);
        this.changeLang = this.changeLang.bind(this);

    }

    async componentWillMount(){
        const { lang, lang_id } = await (currentLanguage.value?currentLanguage.value:authentication.getLanguage());

        this.setState({
            lang:lang,
            lang_id: lang_id,
        });

        this.dataTable();
    }

    dataTable() {

        const requestOptions = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Language': this.state.lang_id,
                'Authorization': 'Basic ' + encode(config.url.USER+':'+config.url.PASS)
            },
        };

        fetch(config.url.API_URL + '/information'
            , requestOptions)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    about_us: data.data.about_us,
                    about_title: data.data.about_us_title
                });
            });
    }

    async changeLang(val, id){
        await this.setState({
            lang: val,
            lang_id: id
        })
        this.dataTable(this.state.type);
    }

    render(){
        //document.getElementById("header-cover").style.background = 'url("uploads/e1.jpg")';
      return (
        <div>
            <BarCover onChageLangSubmit={this.changeLang} background = {'url("uploads/banner.png") no-repeat center center'} title="ABOUT US" method="about" heightBar={window.innerWidth < 919 && "220px" || (window.innerWidth > 919 && window.innerWidth > 1021) && "350px" || (window.innerWidth > 1021) && "450px"}/>
            <div className="main main-detail01">
                <div className="container">
                <article>
                    <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <div className="overflow-visible mb-3">
                            <div className="card-body">
                                <h3 className="text-center mt-4 title-about">&nbsp;&nbsp;&nbsp;{this.state.about_title}</h3>
                                <p className="text-justify p-2 mt-3 detail">&nbsp;&nbsp;&nbsp;{this.state.about_us}</p>
                                </div>
                        </div>
                    </div>
                    </div>
                </article>
                </div>
                <footer />
            </div>
            </div>
    );
    }
  };
